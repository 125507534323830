import React, { useState } from "react";
import axios from "axios";
import LogoImg from "../../img/big logo (1).png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { POST_USER_lOGIN } from "../../constant/constant";
import imagebg from "../../img/login.png";
import image from "../../img/img.jpg";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
// import { toast } from "react-toastify";
const NewUserLogin = () => {
  const [bsgUid, setBsgUid] = useState("");
  const [password, setPassword] = useState("");
  const [showpassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const data = {
      bsgUid: bsgUid,
      password: password,
    };
    if (bsgUid === "" || password === "") {
     return  toast.error("Please Enter All Fields");
    }
    
    // Show loading animation
    Swal.fire({
      title: "Logging in...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await axios.post(POST_USER_lOGIN, data);
      console.log(response,"response")
      Swal.close(); 
      console.log(response.data, "data");
      const userToken = response.data.token;
      const bsgUid = response.data.bsgUid;
      const _id = response.data._id;
      const idString = JSON.stringify(_id);
      localStorage.setItem("_id", idString);
      localStorage.setItem("userToken", userToken);
      localStorage.setItem("bsgUid", bsgUid);

      if (
        response.data.message ===
        "First-time login detected. Please login with bsgUid, dob, and email first."
      ) {
        navigate("/");
      } else if (response.data.message === "Login successful") {
        navigate("/home");
      }
    } catch (error) {
      console.log(error,"error")
      Swal.close(); 

      if (
        error.response &&
        error.response.data.message ===
          "First-time login detected. Please login with bsgUid, dob, and email first."
      ) {
        toast.error(
          "First-time login detected. Please login with bsgUid, dob, and email first."
        );
      } else if (
        error.response &&
        error.response.data.message === "Invalid password"
      ) {
        toast.error("Invalid Password");
      } else if (
        error.response &&
        error.response.data.message === "User not found"
      ) {
        toast.error("User not found");
      } else {
        console.error("An unexpected error occurred:", error);
        toast.error("An unexpected error occurred. Please try again later.");
      }
    }
  };

  return (
    <>
      <div
        className="h-screen w-screen flex items-center justify-center bg-cover bg-center relative "
        style={{ backgroundImage: `url(${imagebg})` }}
      >
        <div className="min-h-screen flex items-center justify-center ">
          <ToastContainer />

          <div className="grid grid-cols-1  lg:grid-cols-2">
            <div>
              <img
                src={image}
                className="hidden lg:block h-[505px] max-w-md w-full shadow-xl rounded-l-lg "
              />
            </div>
            <div className=" p-8  rounded-md lg:rounded-r-lg bg-slate-200 max-w-md w-full  ">
              <img src={LogoImg} alt="Logo" className="h-8 " />
              <h2 className="text-lg font-bold text-center text-black my-8 uppercase">
                Existing Member Log-In
              </h2>
              <div className="mb-4">
                <label
                  htmlFor="bsgUid"
                  className="block text-sm font-medium text-gray-700"
                >
                  BSG UID Number
                </label>
                <input
                  type="text"
                  id="bsgUid"
                  className="bg-gray-200 py-1 px-2 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                  placeholder="Enter BSG UID Number"
                  value={bsgUid}
                  onChange={(e) => setBsgUid(e.target.value)}
                  required
                />
              </div>
              
              <div className="mb-6 relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  type={showpassword ? "text" : "password"}
                  id="password"
                  placeholder="Enter the Password"
                  className="bg-gray-200 py-1 px-2 mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring focus:ring-indigo-200"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div
                  className="absolute inset-y-0 right-2 top-6 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showpassword)}
                >
                  {showpassword ? (
                    <FaEye className="text-gray-500" />
                  ) : (
                    <FaEyeSlash className="text-gray-500" />
                  )}
                </div>
              </div>

              <button
                onClick={handleSubmit}
                type="button"
                className="w-full bg-[#0C2D48] text-white py-2 px-4 rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
              >
                Login
              </button>
<div className="flex justify-between">
              <div
                className="text-[#1D56A5] hover:text-indigo-700 text-sm focus:outline-none mt-10 text-center cursor-pointer"
                onClick={() => navigate("/")}
              >
                Don’t have an account? Sign up
              </div>

              <div className="text-[#1D56A5] hover:text-indigo-700 text-sm focus:outline-none mt-10 text-center cursor-pointer"     onClick={() => navigate("/forgot")}>Forgot Password ?</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewUserLogin;
