// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { BASE_URL } from "../../constant/constant";

// const CourseForm = () => {
//   const [courses, setCourses] = useState([
//     {
//       id: 1,
//       wing: "",
//       subwing: "",
//       fromDate: "",
//       toDate: "",
//       venue: "",
//       certificateNumber: "",
//       certificateDate: "",
//       parchmentDate: "",
//       parchmentNumber: "",
//       uploadCertificate: "",
//       uploadparchment: "",
//       new: true, // mark this course as new
//     },
//   ]);

//   const [subWingOptions, setSubWingOptions] = useState({
//     Scout: ["HWB-Cub", "HWB-Scout", "HWB-Rover"],
//     Guide: ["HWB-Bulbul", "HWB-Guide", "HWB-Ranger"],
//   });

//   const [selectedSubWings, setSelectedSubWings] = useState([]);
//   const [courseDisable, setCourseDisable] = useState([]);

//   const addCourse = () => {
//     const newCourse = {
//       id: courses.length + 1,
//       wing: "",
//       subwing: "",
//       fromDate: "",
//       toDate: "",
//       venue: "",
//       certificateNumber: "",
//       certificateDate: "",
//       parchmentNumber: "",
//       parchmentDate: "",
//       uploadCertificate: "",
//       uploadparchment: "",
//       new: true, // mark this course as new
//     };
//     setCourses([...courses, newCourse]);
//   };

//   const removeCourse = (id) => {
//     const courseToRemove = courses.find((course) => course.id === id);
//     if (courseToRemove.subwing) {
//       setSelectedSubWings(
//         selectedSubWings.filter((subwing) => subwing !== courseToRemove.subwing)
//       );
//     }
//     const updatedCourses = courses.filter((course) => course.id !== id);
//     setCourses(updatedCourses);
//   };

//   const handleChange = (index, field, value) => {
//     const updatedCourses = [...courses];
//     updatedCourses[index][field] = value;
//     setCourses(updatedCourses);

//     if (field === "subwing") {
//       setSelectedSubWings([...selectedSubWings, value]);
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       const storedIdString = localStorage.getItem("_id");
//       const userId = JSON.parse(storedIdString);

//       const newCourses = courses.filter((course) => course.new);

//       for (let i = 0; i < newCourses.length; i++) {
//         const formData = new FormData();

//         formData.append("wing", newCourses[i].wing);
//         formData.append("subwing", newCourses[i].subwing);
//         formData.append("fromDate", newCourses[i].fromDate);
//         formData.append("toDate", newCourses[i].toDate);
//         formData.append("venue", newCourses[i].venue);
//         formData.append("certificateNumber", newCourses[i].certificateNumber);
//         formData.append("certificateDate", newCourses[i].certificateDate);
//         formData.append("parchmentNumber", newCourses[i].parchmentNumber);
//         formData.append("parchmentDate", newCourses[i].parchmentDate);

//         if (newCourses[i].uploadCertificate) {
//           formData.append(
//             "uploadCertificate",
//             newCourses[i].uploadCertificate[0]
//           );
//         }
//         if (newCourses[i].uploadparchment) {
//           formData.append("uploadparchment", newCourses[i].uploadparchment[0]);
//         }

//         const response = await axios.post(
//           `${BASE_URL}/api/v1/hwbDetails/${userId}`,
//           formData,
//           {
//             headers: {
//               "Content-Type": "multipart/form-data",
//             },
//           }
//         );

//         const responseMessage1 = response.data._id;
//         localStorage.setItem("id", responseMessage1);
//       }

//       toast.success("Form Submitted Successfully");

//       // Reset only the new courses after submission
//       setCourses((prevCourses) =>
//         prevCourses.map((course) =>
//           course.new
//             ? {
//                 id: course.id,
//                 wing: "",
//                 subwing: "",
//                 fromDate: "",
//                 toDate: "",
//                 venue: "",
//                 certificateNumber: "",
//                 certificateDate: "",
//                 parchmentDate: "",
//                 parchmentNumber: "",
//                 uploadCertificate: "",
//                 uploadparchment: "",
//                 new: true,
//               }
//             : course
//         )
//       );
//       setSelectedSubWings([]);
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred during registration");
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const storedIdString = localStorage.getItem("_id");
//         const userId = JSON.parse(storedIdString);

//         const response = await axios.get(
//           `${BASE_URL}/api/v1/hwbDetails/${userId}`
//         );

//         const hwbDetails = response.data.map((course) => ({
//           id: course.id,
//           wing: course.wing,
//           subwing: course.subwing,
//           fromDate: course.fromDate,
//           toDate: course.toDate,
//           venue: course.venue,
//           certificateNumber: course.certificateNumber,
//           certificateDate: course.certificateDate,
//           parchmentNumber: course.parchmentNumber,
//           parchmentDate: course.parchmentDate,
//           uploadCertificate: course.uploadCertificate,
//           uploadparchment: course.uploadparchment,
//           new: false, // mark fetched courses as not new
//         }));
//         console.log(hwbDetails, "hwbDetails");

//         if (hwbDetails[0]) {
//           setCourseDisable(new Array(hwbDetails.length).fill(true));
//           setCourses(hwbDetails);
//         }
//       } catch (error) {
//         console.error("Error fetching personal details:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <>
//     <div className="bg-blue-100 border-l-4 border-blue-500 p-2 rounded-md shadow-lg -mt-4">
//     <div className="flex items-start">
//       <svg
//         className="h-6 w-6 text-blue-500 mt-0.5 mr-2"
//         fill="none"
//         viewBox="0 0 24 24"
//         stroke="currentColor"
//       >
//         <path
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           strokeWidth="2"
//           d="M13 16h-1v-4h-1m0-4h.01M12 4v4m-6 8h12v2H6v-2z"
//         />
//       </svg>
//       <div className="text-blue-800">
//         <span className="font-bold text-red-600">Disclaimer:</span>
//         Once you submit this form, you will not be able to make any changes to the information provided. Please review your entries carefully before submitting, as this submission is final and cannot be edited or resubmitted.
//       </div>
//     </div>
//   </div>

//     <div className="max-w-5xl mx-auto">
//       <ToastContainer />
//       <div className="text-center font-bold py-5 text-2xl">HWB COURSE</div>
//       <form onSubmit={handleSubmit} className="mt-8 space-y-6">
//         {courses.map((course, index) => (
//           <div
//             key={course.id}
//             className="p-4 border border-gray-300 rounded mb-4"
//           >
//             <div className="flex justify-end mb-2">
//               {courses.length > 1 && (
//                 <button
//                   type="button"
//                   onClick={() => removeCourse(course.id)}
//                   className="text-red-600 hover:text-red-700"
//                 >
//                   <svg
//                     className="h-5 w-5"
//                     fill="none"
//                     viewBox="0 0 24 24"
//                     stroke="currentColor"
//                   >
//                     <path
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth="2"
//                       d="M6 18L18 6M6 6l12 12"
//                     />
//                   </svg>
//                 </button>
//               )}
//             </div>

//             <div className="grid grid-cols-2 gap-x-20 gap-y-5">
//               <div className="flex flex-col mb-4">
//                 <label className="mb-1 font-medium text-black">Wing</label>
//                 <select
//                   value={course.wing}
//                   disabled={
//                     index < courseDisable.length && courseDisable[index]
//                   }
//                   onChange={(e) => handleChange(index, "wing", e.target.value)}
//                   className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
//                 >
//                   <option value="">Select Wing</option>
//                   <option value="Scout">Scout</option>
//                   <option value="Guide">Guide</option>
//                 </select>
//               </div>
//               {course.wing && (
//                 <div className="mt-2 flex flex-col">
//                   <label className="mb-1 font-medium text-black">
//                     Sub-Wing
//                   </label>
//                   <select
//                     value={course.subwing}
//                     disabled={
//                       index < courseDisable.length && courseDisable[index]
//                     }
//                     onChange={(e) =>
//                       handleChange(index, "subwing", e.target.value)
//                     }
//                     className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
//                   >
//                     <option value="">Select Sub-Wing</option>
//                     {subWingOptions[course.wing].map((option) => (
//                       <option
//                         key={option}
//                         value={option}
//                         disabled={
//                           // Disable if the option is already selected in another course
//                           courses.some(
//                             (c, idx) => idx !== index && c.subwing === option
//                           )
//                         }
//                       >
//                         {option}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               )}

//               <div>
//                 <label className="block text-sm font-bold text-black">
//                   From Date
//                 </label>
//                 <input
//                   type="date"
//                   value={course.fromDate}
//                   disabled={
//                     index < courseDisable.length && courseDisable[index]
//                   }
//                   onChange={(e) =>
//                     handleChange(index, "fromDate", e.target.value)
//                   }
//                   className="w-full px-4 py-2 border border-gray-300 rounded-md"
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-bold text-black">
//                   To Date
//                 </label>
//                 <input
//                   type="date"
//                   value={course.toDate}
//                   disabled={
//                     index < courseDisable.length && courseDisable[index]
//                   }
//                   onChange={(e) =>
//                     handleChange(index, "toDate", e.target.value)
//                   }
//                   className="w-full px-4 py-2 border border-gray-300 rounded-md"
//                 />
//               </div>

//               <div>
//                 <label className="block text-sm font-bold text-black">
//                   Venue
//                 </label>
//                 <input
//                   type="text"
//                   value={course.venue}
//                   disabled={
//                     index < courseDisable.length && courseDisable[index]
//                   }
//                   onChange={(e) => handleChange(index, "venue", e.target.value)}
//                   className="w-full px-4 py-2 border border-gray-300 rounded-md"
//                 />
//               </div>

//               <div>
//                 <label className="block text-sm font-bold text-black">
//                   Certificate Number
//                 </label>
//                 <input
//                   type="text"
//                   value={course.certificateNumber}
//                   disabled={
//                     index < courseDisable.length && courseDisable[index]
//                   }
//                   onChange={(e) =>
//                     handleChange(index, "certificateNumber", e.target.value)
//                   }
//                   className="w-full px-4 py-2 border border-gray-300 rounded-md"
//                 />
//               </div>
//               <div>
//                 <label className="block text-sm font-bold text-black">
//                   Certificate Date
//                 </label>
//                 <input
//                   type="date"
//                   value={course.certificateDate}
//                   disabled={
//                     index < courseDisable.length && courseDisable[index]
//                   }
//                   onChange={(e) =>
//                     handleChange(index, "certificateDate", e.target.value)
//                   }
//                   className="w-full px-4 py-2 border border-gray-300 rounded-md"
//                 />
//               </div>

//               <div>
//                 <label className="block text-sm font-bold text-black">
//                   Parchment Number
//                 </label>
//                 <input
//                   type="text"
//                   value={course.parchmentNumber}
//                   disabled={
//                     index < courseDisable.length && courseDisable[index]
//                   }
//                   onChange={(e) =>
//                     handleChange(index, "parchmentNumber", e.target.value)
//                   }
//                   className="w-full px-4 py-2 border border-gray-300 rounded-md"
//                 />
//               </div>

//               <div>
//                 <label className="block text-sm font-bold text-black">
//                   Parchment Date
//                 </label>
//                 <input
//                   type="date"
//                   value={course.parchmentDate}
//                   disabled={
//                     index < courseDisable.length && courseDisable[index]
//                   }
//                   onChange={(e) =>
//                     handleChange(index, "parchmentDate", e.target.value)
//                   }
//                   className="w-full px-4 py-2 border border-gray-300 rounded-md"
//                 />
//               </div>

//               <div>
//                 <label className="block text-sm font-bold text-black">
//                   Upload Certificate
//                 </label>
//                 {course.uploadCertificate && (
//                   <iframe
//                     src={course.uploadCertificate}
//                     width="100%"
//                     height="150px"
//                     title="PDF Viewer"
//                   ></iframe>
//                 )}{" "}
//                 <input
//                   type="file"
//                   onChange={(e) =>
//                     handleChange(index, "uploadCertificate", e.target.files)
//                   }
//                   disabled={
//                     index < courseDisable.length && courseDisable[index]
//                   }
//                   className="w-full px-4 py-2 border border-gray-300 rounded-md"
//                 />
//               </div>

//               <div>
//                 <label className="block text-sm font-bold text-black">
//                   Upload Parchment
//                 </label>

//                 <div className="mt-2">
//                   <iframe
//                     src={course.uploadparchment}
//                     width="100%"
//                     height="150px"
//                     title="PDF Viewer"
//                   ></iframe>
//                   <input
//                     type="file"
//                     onChange={(e) =>
//                       handleChange(index, "uploadparchment", e.target.files)
//                     }
//                     disabled={
//                       index < courseDisable.length && courseDisable[index]
//                     }
//                     className="w-full px-4 py-2 border border-gray-300 rounded-md"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}

//         <div className="flex justify-between mt-6">
//           <button
//             type="button"
//             onClick={addCourse}
//             className="px-6 py-2 bg-[#1D56A5] text-white rounded-md "
//           >
//             Add Course
//           </button>
//           <button
//             type="submit"
//             className="px-6 py-2 bg-[#1D56A5] text-white rounded-md "
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//     </div>
//     </>
//   );
// };

// export default CourseForm;




// import React, { useEffect, useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const Home = () => {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState("");

//   const getUser = async () => {
//     setLoading(true);
//     setError(""); // Clear previous error
//     try {
//       const bsgUid = localStorage.getItem("bsgUid");
//       const response = await fetch(
//         `${BASE_URL}/api/v1/user/${bsgUid}`
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch user data");
//       }
//       const data = await response.json();
//       console.log(data,"data")
//       setUser(data);
//     } catch (err) {
//       setError(err.message);
//       toast.error(`Error fetching user data: ${err.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getUser();
//   }, []);

//   return (
//     <>
//       <div className="flex flex-col items-center justify-center p-10 ">
//         <h className="text-center font-bold py-5 text-2xl">HWB COURSE</h>
//         <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-10">
//           {loading ? (
//             <p className="text-gray-500 text-center">Loading user data...</p>
//           ) : error ? (
//             <p className="text-red-500 font-bold text-center">{error}</p>
//           ) : user ? (
//             <div className=" grid grid-cols-2 gap-20">
//               <p>
//                 <span className="font-bold">Wing:</span>{" "}
//                 {user.altDetails?.SECTION === "Bulbul" || user.altDetails?.SECTION === "Guide" || user.altDetails?.SECTION === "Rangers" ? (
//   <div>Guide</div>
// ) : (
//   <div>Scout</div>
// )}

//               </p>
//               <p>
//                 <span className="font-bold">Sub-Wing:</span>{" "}
//                 {user.altDetails?.SECTION}
//               </p>
//               <p>
//                 <span className="font-bold">To Date:</span>{" "}
//                 {/* {user.altDetails?.STATE} */}
//               </p>
//               <p>
//                 <span className="font-bold">From Date:</span>{" "}
//                 {/* {user.altDetails?.STATE} */}
//               </p>
//               <p>
//                 <span className="font-bold">Venue:</span>{" "}
//                 {/* {user.altDetails?.STATE} */}
//               </p>
//               <p>
//                 <span className="font-bold">Certificate Number:</span>{" "}
//                 {/* {user.altDetails?.STATE} */}
//               </p>

//               <p>
//                 <span className="font-bold">Certificate Date:</span>{" "}
//                 {/* {user.altDetails?.STATE} */}
//               </p>

//               <p>
//                 <span className="font-bold">State:</span>{" "}
//                 {user.altDetails?.STATE}
//               </p>
//               <p>
//                 <span className="font-bold">Section:</span>{" "}
//                 {user.altDetails?.SECTION}
//               </p>
//               <p>
//                 <span className="font-bold">Parchment No:</span>{" "}
//                 {user.altDetails?.PARCHMENT_NO}
//               </p>
//               <p>
//                 <span className="font-bold">Parchment Date:</span>{" "}
//                 {user.altDetails?.ISSUED_DATE}
//               </p>
//               <p>
//                 <span className="font-bold">Remarks:</span>{" "}
//                 {user.altDetails?.REMARKS}
//               </p>
//             </div>
//           ) : (
//             <p className="text-gray-500 text-center">No user data available</p>
//           )}
//         </div>
//         <ToastContainer />
//       </div>
//     </>
//   );
// };

// export default Home;
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../constant/constant";

const Home = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const token = localStorage.getItem("userToken");
  console.log(token,"token")
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const getUser = async () => {
    setLoading(true);
    setError(""); // Clear previous error
    try {
      const bsgUid = localStorage.getItem("bsgUid");
      const response = await fetch(
        `${BASE_URL}/api/v1/user/${bsgUid}`,axiosConfig,
      );
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const data = await response.json();
      console.log(data, "data");
      setUser(data);
    } catch (err) {
      setError(err.message);
      toast.error(`Error fetching user data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="flex items-center justify-center p-6">
      <div className="w-full max-w-3xl bg-white  rounded-lg p-8">
        <h1 className="text-3xl font-bold text-center text-black mb-14 uppercase">
          HWB Course Details
        </h1>
        {loading ? (
          <p className="text-gray-500 text-center text-lg">Loading user data...</p>
        ) : error ? (
          <p className="text-red-500 font-bold text-center text-lg">{error}</p>
        ) : user ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 border border-black p-8">
            <p>
              <span className="font-bold text-black">Wing:</span>{" "}
              {user.altDetails?.SECTION === "Bulbul" ||
              user.altDetails?.SECTION === "Guide" ||
              user.altDetails?.SECTION === "Rangers" ? (
                <span>Guide</span>
              ) : (
                <span>Scout</span>
              )}
            </p>
            <p>
              <span className="font-bold text-black">Sub-Wing:</span>{" "}
              {user.altDetails?.SECTION}
            </p>
            <p>
              <span className="font-bold text-black">To Date:</span>{" "}
              {/* Add To Date */}  {user.altDetails?.TO_DATE}
            </p>
            <p>
              <span className="font-bold text-black">From Date:</span>{" "}
              {/* Add From Date */}  {user.altDetails?.FROM_DATE}
            </p>
            <p>
              <span className="font-bold text-black">Venue:</span>{" "}
              {/* Add Venue */}{user.altDetails?.PLACE}
            </p>
            <p>
              <span className="font-bold text-black">
                Certificate Number:
              </span>{" "}
              {/* Add Certificate Number */}{user.altDetails?.CERTIFICATE_NUMBER}
            </p>
            <p>
              <span className="font-bold text-black">
                Certificate Date:
              </span>{" "}{user.altDetails?.CERTIFICATE_DATE}
              {/* Add Certificate Date */}
            </p>
            <p>
              <span className="font-bold text-black">State:</span>{" "}
              {user.altDetails?.STATE}
            </p>
            <p>
              <span className="font-bold text-black">Section:</span>{" "}
              {user.altDetails?.SECTION}
            </p>
            <p>
              <span className="font-bold text-black">Parchment No:</span>{" "}
              {user.altDetails?.PARCHMENT_NO}
            </p>
            
          
          </div>
        ) : (
          <p className="text-gray-500 text-center text-lg">
            No user data available
          </p>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Home;
