// import React, { useState } from "react";
// import Cookies from "js-cookie";
// import axios from "axios";
// import LogoImg from "../../img/big logo (1).png";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";
// import { POST_SET_PASSWORD } from "../../constant/constant";
// import imagebg from "../../img/login.png";
// import image from "../../img/img.jpg";
// const SetPassword = () => {
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const navigate = useNavigate();
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const token = Cookies.get("token");
//     console.log(token, "token");
//     if (password === confirmPassword) {
//       try {
//         const data = {
//           password: password,
//           token: token,
//         };
//         console.log(data, "data");
//         // Make POST request to set password API
//         const response = await axios.post(POST_SET_PASSWORD, data);
//         console.log(response.data, "response");
//         // Handle success
//         toast.success("Password updated successfully!");
//         navigate("/home");
//       } catch (error) {
//         // Handle error
//         console.error("Error setting password:", error);
//         toast.error("Failed to update password. Please try again.");
//       }
//     } else {
//       toast.error("Passwords do not match. Please check again.");
//     }
//   };

//   return (
//     <>
//       <div
//         className="h-screen w-screen flex items-center justify-center bg-cover bg-center relative "
//         style={{ backgroundImage: `url(${imagebg})` }}
//       >
//         <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2">
//           <div>
//             <img
//               src={image}
//               className="h-[505px] max-w-md w-full shadow-xl rounded-l-lg"
//             />
//           </div>
//           {/* <div className="bg-white p-8 rounded shadow-lg max-w-md w-full my-20"> */}
//           <img src={LogoImg} alt="Logo" className="h-8" />

//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <form
//               onSubmit={handleSubmit}
//               style={{
//                 width: "300px",
//                 padding: "20px",
//               }}
//             >
//               <h2
//                 className="text-2xl font-bold text-center mb-6"
//                 style={{ textAlign: "center" }}
//               >
//                 Reset Password
//               </h2>
//               <ToastContainer />
//               <div style={{ marginBottom: "15px" }}>
//                 <label className="text-sm font-medium text-gray-700">
//                   Password
//                 </label>
//                 <input
//                   type="password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   className="bg-slate-200 py-1 px-1 outline-none mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//                   required
//                 />
//               </div>
//               <div style={{ marginBottom: "20px" }}>
//                 <label className="text-sm font-medium text-gray-700">
//                   Confirm Password
//                 </label>
//                 <input
//                   type="password"
//                   value={confirmPassword}
//                   onChange={(e) => setConfirmPassword(e.target.value)}
//                   className="bg-slate-200 py-1 px-1 outline-none mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//                   required
//                 />
//               </div>

//               <button
//                 type="submit"
//                 className="w-full bg-[#0C2D48] text-white py-1 px-4 rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
//               >
//                 Save Password
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SetPassword;


import React, { useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import LogoImg from "../../img/big logo (1).png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { POST_SET_PASSWORD } from "../../constant/constant";
import imagebg from "../../img/login.png";
import image from "../../img/img.jpg";

const SetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = Cookies.get("token");

    if (password === confirmPassword) {
      try {
        const data = { password, token };
        await axios.post(POST_SET_PASSWORD, data);
        toast.success("Password updated successfully!");
        navigate("/home");
      } catch (error) {
        toast.error("Failed to update password. Please try again.");
      }
    } else {
      toast.error("Passwords do not match. Please check again.");
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${imagebg})` }}
    >
      <div className="bg-white shadow-lg rounded-lg flex flex-col lg:flex-row items-center lg:items-stretch overflow-hidden max-w-4xl w-full">
        <div className="hidden lg:block bg-gray-100">
          <img
            src={image}
            alt="Side visual"
            className="h-full object-cover"
            style={{ maxHeight: "500px" }}
          />
        </div>
        <div className="flex flex-col items-center justify-center p-8 w-full lg:w-2/3">
          <img src={LogoImg} alt="Logo" className="h-8 mb-6 " />
          <form onSubmit={handleSubmit} className="w-full max-w-sm">
            <h2 className="text-xl font-bold text-gray-800 text-center mb-6">
              Reset Password
            </h2>
            <ToastContainer />
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                New Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="New Password"
                className="w-full mt-2 px-4 py-1 bg-gray-100 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                className="w-full mt-2 px-4 py-1 bg-gray-100 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-1 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Save Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
