import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Sidebar from "./component/Sidebar";
import Login from "./component/Layout/Login";
import SetPassword from "./component/Layout/SetPassword";
import NewUserLogin from "./component/Layout/ExistUserLogin";
import image from "../src/img/home.jpeg";
import Footer from "./component/Footer";
import ForgotPassword from "./component/Layout/ForgotPassword";

const App = () => {
  const location = useLocation();

  // Check if the current location matches '/setpassword' or '/newuserlogin'
  const isLoginRoute = location.pathname === "/";
  const isSetPasswordRoute = location.pathname === "/setpassword";
  const isNewUserLoginRoute = location.pathname === "/newuserlogin";
  const isNewUserForgotRoute = location.pathname === "/forgot";

  return (
    <>
      {/* Display Sidebar only if it's not a login, setpassword, or newuserlogin route */}
      {!isLoginRoute && !isSetPasswordRoute && !isNewUserLoginRoute &&  !isNewUserForgotRoute && (
        <>
        <div className="py-5 pt-20 lg:pt-0">
        <div className="flex justify-center text-center text-[#1D56A5] uppercase  font-bold text-3xl mt-5">Welcome to trainer's Portal </div>
        <div className=" flex justify-center text-center text-[#1D56A5] uppercase  font-bold text-2xl">THE BHARAT SCOUTS & GUIDES</div>
        </div>
          <div>
            <img src={image} alt="Logo" className="h-60 w-full " />
          </div>
          <Sidebar />
          <Footer/>
        </>
      )}

      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/setpassword" exact element={<SetPassword />} />
        <Route path="/newuserlogin" exact element={<NewUserLogin />} />
        <Route path="/forgot" element={<ForgotPassword />} />
      </Routes>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
