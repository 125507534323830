import React, { useState } from 'react';
import axios from 'axios';
import { FaInstagram, FaMapMarkerAlt, FaTwitter, FaFacebook, FaYoutube, FaWhatsapp, FaShoppingCart } from 'react-icons/fa';
import bsgLogo from '../img/small logo.png';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscribe = async () => {
    try {
      const response = await axios.post("https://bw-handwash.bsgindia.org/api/v1/subscribe", { email });
      setMessage('Subscription successful! Thank you for subscribing.');
    } catch (error) {
      setMessage('Subscription failed. Please try again.');
    }
  };

  return (
<>
  <div className="container mx-auto px-6 md:px-12 lg:px-20 bg-[#1D56A5] py-5">
    <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">
      {/* Left Column */}
      <div className="flex flex-col items-center md:items-start text-center md:text-left md:w-1/3">
        <img src={bsgLogo} alt="BSG Logo" className="w-40 mb-6" />
        <p className="text-xs text-gray-300 leading-relaxed">
          The Bharat Scouts and Guides, National Headquarters, <br />
          16 Mahatma Gandhi Marg, I. P. Estate, New Delhi - 110002
        </p>
        <div className="flex space-x-2 mt-6">
          <a
            href="https://www.instagram.com/bsgindia/"
            target="_blank"
            aria-label="Instagram"
            className="text-white text-xl hover:text-pink-400 transition duration-300 ease-in-out"
          >
            <FaInstagram />
          </a>
          <a
            href="https://maps.app.goo.gl/wzmTgSVtqonmAnUc8"
            aria-label="Location"
            className="text-white text-xl hover:text-red-300 transition duration-300 ease-in-out"
          >
            <FaMapMarkerAlt />
          </a>
          <a
            href="https://x.com/bsgnhq"
            target="_blank"
            aria-label="Twitter"
            className="text-white text-xl hover:text-yellow-400 transition duration-300 ease-in-out"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.facebook.com/bsgnhq"
            aria-label="Facebook"
            className="text-white text-xl hover:text-yellow-500 transition duration-300 ease-in-out"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.youtube.com/@BSGIndia"
            target="_blank"
            aria-label="YouTube"
            className="text-white text-xl hover:text-yellow-500 transition duration-300 ease-in-out"
          >
            <FaYoutube />
          </a>
          <a
            href="https://www.shop.bsgindia.org/"
            aria-label="Cart"
            className="text-white text-xl hover:text-yellow-500 transition duration-300 ease-in-out"
          >
            <FaShoppingCart />
          </a>
        </div>
      </div>

      {/* Center Column */}
      <div className="md:w-1/3 text-center md:text-left">
        <h3 className="font-semibold text-xl mb-4 text-yellow-400">Contact Us</h3>
        <p className="text-gray-300 text-sm">
          🌐{" "}
          <a
            href="https://www.bsgindia.org"
            className="hover:text-white transition-colors duration-200"
          >
            bsgindia.org
          </a>
        </p>
        <p className="text-gray-300 text-sm">📧 Email: info@bsgindia.org</p>
        <p className="text-gray-300 text-sm">📞 Phone: (011) 23370724, 23378667</p>
      </div>

      {/* Right Column */}
      <div className="md:w-1/3 text-center md:text-left">
        <h3 className="font-semibold text-xl mb-4 text-yellow-400">
          Subscribe to Our Newsletter
        </h3>
        <div className="flex flex-col space-y-4">
          <input
            type="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className=" px-2 py-1 rounded-sm border-0 bg-white text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-yellow-400 outline-none"
          />
          <button
            onClick={handleSubscribe}
            className="w-full bg-yellow-500 text-white py-1 rounded-sm hover:bg-yellow-600 transition-all duration-200 shadow-lg font-medium"
          >
            Subscribe
          </button>
          {message && (
            <p className="mt-2 text-sm text-gray-300 italic">{message}</p>
          )}
        </div>
      </div>
    </div>
  </div>
  <div className="bg-[#163E80] text-center py-4">
    <p className="text-xs text-gray-400">
      Copyright © 2024 The Bharat Scouts and Guides. All rights reserved.
    </p>
  </div>
</>

  );
};

export default Footer;
