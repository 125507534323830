import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../constant/constant";
import { useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import Download from "../Download";

const PersonalInformation = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [employ, setEmployed] = useState("");
  const [dob, setDob] = useState("");
  const [bsgUid, setBsgUid] = useState("");
  const [state, setState] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [revenuePincode, setRevenuePincode] = useState("");
  const [uploadPhoto, setUploadPhoto] = useState(null);
  const [warrantNumber, setWarrantNumber] = useState("");
  const [valid, setWarrantValid] = useState("");
  const [warrantDate, setWarrantDate] = useState("");
  const [skill, setSkill] = useState("");
  const [uploadwarrant, setWarrantUpload] = useState(null);
  const [occupation, setOccupation] = useState("");
  const [qualification, setQualification] = useState("");
  const [place, setPlace] = useState("");
  const [apply, setApply] = useState("");
  const [age, setAge] = useState("");
  const [form1, setForm1] = useState("");
  const [form2, setForm2] = useState("");
  const [deatails, setDetails] = useState("");
  const [service, setService] = useState("");
  const [book, setBook] = useState("");
  const [listofbook, setListofbook] = useState("");
  const [subject, setSubject] = useState("");
  const [rank, setRank] = useState("");
  const [prealtcertificate, setPreAltCertificate] = useState(null);
  const [prealtcertificate1, setPreAltCertificate1] = useState(null);
  const [basic, setBasic] = useState([]);
  const [advance, setAdvance] = useState([]);
  const [hwb, setHwb] = useState([]);
  const [progress, setProgress] = useState(0);
  const [courseDisable, setCourseDisable] = useState([]);
  const [trainingcourse, setTrainingCourse] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState("");
  const [date, setDate] = useState([]);
  const [bsgDate, setBsgDate] = useState("");
  const [formData1, setFormData1] = useState([]);

  const [forms, setForms] = useState([
    {
      course: "",
      participant: "",
      fromDate: "",
      toDate: "",
      work: "",
      leader: "",
      uploadcertificate1: "null",
      uploadcertificate: "",
    },
  ]);

  const addMore = () => {
    const newForm = {
      course: "",
      participant: "",
      fromDate: "",
      toDate: "",
      work: "",
      leader: "",
      uploadcertificate: "",
    };
    setForms([...forms, newForm]);
  };

  const getProgressLabel = () => {
    if (progress === 0) {
      return "Form1";
    } else if (progress === 50) {
      return "Form2";
    } else if (progress === 100) {
      return "Completed";
    }
    return "";
  };

  const navigate = useNavigate();
  const validateForm = () => {
    // Check if any course has empty fields
    return forms.every(
      (forms) =>
        forms.course &&
        forms.participant &&
        forms.fromDate &&
        forms.toDate &&
        forms.work &&
        forms.leader &&
        forms.uploadcertificate
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmptyField = 
      name === "" ||
      email === "" ||
      employ === "" ||
      dob === "" ||
      bsgUid === "" ||
      bsgDate === "" ||
      state === "" ||
      mobileNumber === "" ||
      permanentAddress === "" ||
      revenuePincode === "" ||
      warrantNumber === "" ||
      valid === "" ||
      warrantDate === "" ||
      skill === "" ||
      occupation === "" ||
      qualification === "" ||
      age === "" ||
      deatails === "" ||
      service === "" ||
      book === "" ||
      listofbook === "" ||
      subject === "" ||
      rank === "";

if (isEmptyField || !validateForm()) {
  toast.error("Please fill out all fields before submitting.");
  return false
}

    const progress1 = 50;

    const formData = new FormData();
    const formDetails = [];
    for (let i = 0; i < forms.length; i++) {
      const formObject = {
        course: forms[i].course,
        fromDate: forms[i].fromDate,
        toDate: forms[i].toDate,
        work: forms[i].work,
        leader: forms[i].leader,
        participant: forms[i].participant,
      };
      if (forms[i].uploadcertificate) {
        formObject.uploadcertificate = `uploadcertificate_${i}`;

        formData.append(`uploadcertificate_${i}`, forms[i].uploadcertificate);
      }
      formDetails.push(formObject);
    }
    formData.append("name", name);
    formData.append("email", email);
    formData.append("employ", employ);
    formData.append("dob", dob);
    formData.append("bsgUid", bsgUid);
    formData.append("state", state);
    formData.append("mobileNumber", mobileNumber);
    formData.append("permanentAddress", permanentAddress);
    formData.append("revenuePincode", revenuePincode);
    if (uploadPhoto) {
      formData.append("uploadPhoto", uploadPhoto);
    }
    formData.append("warrantNumber", warrantNumber);
    formData.append("valid", valid);
    formData.append("warrantDate", warrantDate);
    formData.append("skill", skill);
    if (uploadwarrant) {
      formData.append("uploadWarrant", uploadwarrant);
    }
    formData.append("occupation", occupation);
    formData.append("qualification", qualification);
    formData.append("place", place);
    formData.append("apply", apply);
    formData.append("age", age);
    formData.append("deatails", deatails);
    formData.append("bsgDate", bsgDate);
    formData.append("book", book);
    formData.append("listofbook", listofbook);
    formData.append("subject", subject);
    formData.append("progress", progress1);
    formData.append("service", service);
    formData.append("prealtcertificate", prealtcertificate);
    formData.append("rank", rank);
    formData.append("basic", JSON.stringify(basic));
    formData.append("training", JSON.stringify(formDetails));
    formData.append("advance", JSON.stringify(advance));
    formData.append("hwb", JSON.stringify(hwb));
    console.log(formDetails, trainingcourse, "basic");

    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);

      if (isSubmitted) {
        toast("Form is already submitted");
        return;
      }
      const response = await axios.post(
        `${BASE_URL}/api/v1/altapplicationDetails/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data, "response");
      setFormData1(response.data, "sdsf");
      console.log(response.data, "SSDFGHJKJHGF");
      const responseMessage1 = response.data._id;

      localStorage.setItem("id", responseMessage1);
      toast.success("ALT Form Submitted Successfully");
    } catch (error) {
      toast("An error occurred during registration");
    }
  };

  useEffect(() => {
    getDataa();
    getData();
    getBasicData();
    getAdvanceData();
    getHwbDetail();
  }, []);

  const getDataa = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);
      const response = await axios.get(
        `${BASE_URL}/api/v1/personaldetails/${userId}`
      );

      const email = response.data.email;
      const formattedDob = response?.data?.dob.split("-").reverse().join("-");

      setDob(formattedDob);
      calculateAge(formattedDob);
      const bsgUidd = response.data.bsgUid;
      setEmail(email);
      setBsgUid(bsgUidd);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getBasicData = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);

      const response = await axios.get(
        `${BASE_URL}/api/v1/basicDetails/${userId}`
      );

      setBasic(response.data);
      console.log(response.data, "basic");
    } catch (error) {
      toast.error("Error ");
    }
  };

  const getAdvanceData = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);

      const response = await axios.get(
        `${BASE_URL}/api/v1/advancedDetails/${userId}`
      );
      console.log(response, "response");
      console.log(response.data, "dbjsas");
      setAdvance(response.data);
    } catch (error) {
      toast.error("Error ");
    }
  };

  const getHwbDetail = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);

      const response = await axios.get(
        `${BASE_URL}/api/v1/hwbDetails/${userId}`
      );

      setHwb(response.data);
    } catch (error) {
      toast.error("Error ");
    }
  };

  const getData = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);

      const response = await axios.get(
        `${BASE_URL}/api/v1/personaldetails/${userId}`
      );

      const personalDetails = response.data; // Assuming response.data contains all personal details

      if (!personalDetails.status) {
        toast.success("Firstly fill Personal Details");
      }
    

      if (personalDetails) {
        // setCourseDisable(new Array(personalDetails.length).fill(true));

        setName(personalDetails.name);
        setEmail(personalDetails.email);
        setBsgUid(personalDetails.bsgUid);
        setState(personalDetails.state);
        setUploadPhoto(personalDetails.uploadPhoto);
        setPermanentAddress(personalDetails.permanentAddress);
        setMobileNumber(personalDetails.mobileNumber);
        setDob(personalDetails.dob);
        calculateAge(personalDetails.dob);
        setRevenuePincode(personalDetails.revenuePincode);
        setWarrantNumber(personalDetails.warrantNumber);
        setWarrantDate(personalDetails.warrantDate);
        setWarrantValid(personalDetails.valid);
        setWarrantUpload(personalDetails.uploadWarrant);
        setWarrantDate(personalDetails.warrantDate);
        setOccupation(personalDetails.occupation);
        setPlace(personalDetails.place);
        setQualification(personalDetails.qualification);

        console.log(personalDetails, "personal Details");
      }
    } catch (error) {
      console.error("Error fetching personal details:", error);
    }
  };

  function isValidEmail(email) {
    // Regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleChange = (index, field, value) => {
    const updatedCourses = [...forms];
    updatedCourses[index][field] = value;
    setForms(updatedCourses);
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    console.log(age, "age");
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setAge(age);
    return age;
  };
  useEffect(() => {
    calculateAge();
  }, []);
  const handleCalculateAge = (event) => {
    const dobValue = event.target.value;
    console.log(dobValue, "docValue");
    setDob(dobValue);
    if (dobValue) {
      const calculatedAge = calculateAge(dobValue);
      setAge(calculatedAge);
    } else {
      setAge("");
    }
  };

  useEffect(() => {
    getAllByAlt();
  }, []);

  const getAllByAlt = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      if (storedIdString) {
        const userId = JSON.parse(storedIdString);
        const response = await axios.get(
          `${BASE_URL}/api/v1/getaltapplicationDetails/${userId}`
        );
        console.log(response.data, "all alt details");

        const getAllalt = response.data;
        const getAllaltTrainingCourse = response.data.TrainingCourse;
        console.log(getAllaltTrainingCourse, "dsfsbjfh");

        const altTrainingCourse = getAllaltTrainingCourse.map((forms) => ({
          course: forms.course,
          fromDate: forms.fromDate,
          toDate: forms.toDate,
          work: forms.work,
          venue: forms.venue,
          leader: forms.leader,

          participant: forms.participant,
          uploadcertificate1: forms.uploadcertificate,
        }));
        console.log(altTrainingCourse, "altTrainingCourse");

        if (altTrainingCourse[0]) {
          setCourseDisable(new Array(altTrainingCourse.length).fill(true));
          setForms(altTrainingCourse);
        }

        if (getAllalt) {
          setBook(getAllalt.book);
          setApply(getAllalt.apply);
          setEmployed(getAllalt.employ);
          setListofbook(getAllalt.listofbook);
          setSkill(getAllalt.skill);
          setService(getAllalt.service);
          setDetails(getAllalt.deatails);
          setSubject(getAllalt.subject);
          setRank(getAllalt.rank);
          setProgress(getAllalt.progress);
          setPreAltCertificate1(getAllalt.prealtcertificate);
          setIsSubmitted(getAllalt.isSubmitted);
          setBsgDate(getAllalt.bsgDate);
        } else {
          console.log("error");
        }
      } else {
        console.error("No user ID found in local storage.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDate();
  }, []);

  const getDate = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");

      const userId = JSON.parse(storedIdString);

      const response = await axios.get(
        `${BASE_URL}/api/v1/getdate/${userId}`
      );
      console.log(response, "response");
      console.log(response.data);
      const result = response.data;
      setDate(result);
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <>
 <div className="bg-blue-100 border-l-4 border-blue-500 p-2 rounded-md shadow-lg -mt-4">
  <div className="flex items-start">
    <svg
      className="h-6 w-6 text-blue-500 mt-0.5 mr-2"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 16h-1v-4h-1m0-4h.01M12 4v4m-6 8h12v2H6v-2z"
      />
    </svg>
    <div className="text-blue-800">
      <span className="font-semibold text-red-600">Disclaimer:</span> 
      Once you submit this form, you will not be able to make any changes to the information provided. Please review your entries carefully before submitting, as this submission is final and cannot be edited or resubmitted.
    </div>
  </div>
</div>


      <div className=" text-center font-bold text-3xl pt-5 ">
        ALT APPLICATION FORM 
      </div>

      <div className="flex justify-end text-end my-5">
        {isSubmitted ? <Download formData1={formData1} /> : <p></p>}
      </div>

      {/* <div
        style={{ position: "relative", width: "100%", marginBottom: "20px" }}
      >
        <ProgressBar completed={progress} />
        <div className="font-medium">{getProgressLabel()}</div>
      </div> */}

      <div className="flex justify-center items-center mb-8">
        <div className="pt-5  max-w-4xl  rounded-lg shadow-md">
          <div className="px-8 ">
            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">Name</label>
                <input
                  type="text"
                  value={name}
                  disabled={courseDisable.length}
                  placeholder="Enter the Full Name"
                  onChange={(e) => setName(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">Apply for</label>

                <select
                  value={bsgDate} // Replace with your state variable for the selected value
                  disabled={courseDisable.length > 0} // Assuming courseDisable is an array
                  onChange={(e) => setBsgDate(e.target.value)} // Replace setSelectedState with your state setter
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                >
                  <option value={-1}>Select BSG Date</option>
                  {date &&
                    date.map((item, index) => (
                      <option key={index} value={item.date}>
                        {item.date}
                      </option>
                    ))}
                </select>
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Uploaded Photo
                </label>

                {uploadPhoto ? (
                  <img
                    src={uploadPhoto}
                    alt="photo"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                ) : (
                  <input
                    type="file"
                    className="outline-none mb-3 mt-1 py-2 bg-slate-200 px-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(e) => setUploadPhoto(e.target.files[0])}
                  />
                )}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10 ">
              <div className="flex flex-col">
                <label className="mb-1 font-medium text-black">Email</label>
                <input
                  type="text"
                  value={email}
                  disabled={courseDisable.length}
                  placeholder="Enter the Email"
                  onChange={(e) => setEmail(e.target.value)}
                  className={`outline-none bg-white rounded-md px-3 py-1 border ${
                    isValidEmail(email) ? "border-gray-300" : "border-red-500"
                  } focus:border-indigo-500`}
                />
                {!isValidEmail(email) && (
                  <p className="text-red-500 text-sm mt-1">
                    Please enter a valid email address.
                  </p>
                )}
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Mobile Number
                </label>
                <input
                  type="text"
                  value={mobileNumber}
                  disabled={courseDisable.length}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder="Enter the Mobile Number"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Home Address
                </label>
                <textarea
                  type="text"
                  value={permanentAddress}
                  disabled={courseDisable.length}
                  onChange={(e) => setPermanentAddress(e.target.value)}
                  placeholder="Enter the Permanent Address"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10 -mt-5">
              <div className="flex flex-col ">
                <label className="mb-1 font-medium text-black">BSG State</label>
                <input
                  type="text"
                  value={state}
                  disabled={courseDisable.length}
                  placeholder="Enter the State"
                  onChange={(e) => setState(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">BSGUID </label>
                <input
                  type="text"
                  value={bsgUid}
                  disabled={courseDisable.length}
                  placeholder="Enter the UID"
                  onChange={(e) => setBsgUid(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">Age</label>
                <input
                  type="text"
                  value={age}
                  disabled={courseDisable.length}
                  // onChange={(e) => setAge(e.target.value)}
                  placeholder="Enter the Age"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">DOB</label>
                <input
                  type="date"
                  value={dob}
                  disabled={courseDisable.length}
                  // onChange={handleCalculateAge}
                  placeholder="Enter your Date of Birth (YYYY-MM-DD)"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Whether have been a Cub / Scout / Rover, if so give details,
                  Place, Years etc.
                </label>
                <textarea
                  type="text"
                  value={deatails}
                  disabled={courseDisable.length}
                  onChange={(e) => setDetails(e.target.value)}
                  placeholder="Enter the Details"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Service in the Movement as an Adult Leaders. Give details.
                  Rank, Years, Warrant Nos.
                </label>
                <textarea
                  type="text"
                  value={service}
                  disabled={courseDisable.length}
                  onChange={(e) => setService(e.target.value)}
                  placeholder="Enter the Details"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  List of books on Scouting you have studied.
                </label>
                <textarea
                  type="text"
                  value={book}
                  disabled={courseDisable.length}
                  onChange={(e) => setBook(e.target.value)}
                  placeholder="Enter the Name"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  List of books on Scouting you have studied.
                </label>
                <textarea
                  type="text"
                  value={listofbook}
                  disabled={courseDisable.length}
                  onChange={(e) => setListofbook(e.target.value)}
                  placeholder="Enter the Name"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>{" "}
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Scout subject in which you are well versed.
                </label>
                <textarea
                  type="text"
                  value={subject}
                  disabled={courseDisable.length}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Enter the Subject"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  I now hold warrant for rank
                </label>
                <textarea
                  type="text"
                  value={rank}
                  disabled={courseDisable.length}
                  onChange={(e) => setRank(e.target.value)}
                  placeholder="Enter the Warrant Name"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">Pincode</label>
                <input
                  type="text"
                  value={revenuePincode}
                  disabled={courseDisable.length}
                  onChange={(e) => setRevenuePincode(e.target.value)}
                  placeholder="Enter the Pincode"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Where employed / How long
                </label>

                <input
                  type="text"
                  name="avatar"
                  disabled={courseDisable.length}
                  placeholder="Enter the Details"
                  value={employ}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                  onChange={(e) => setEmployed(e.target.value)}
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Warrant Number
                </label>
                <input
                  type="text"
                  value={warrantNumber}
                  disabled={courseDisable.length}
                  onChange={(e) => setWarrantNumber(e.target.value)}
                  placeholder="Enter the Warrant Number"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Warrant Date
                </label>
                <input
                  type="date"
                  value={warrantDate}
                  disabled={courseDisable.length}
                  onChange={(e) => setWarrantDate(e.target.value)}
                  placeholder="Enter the Date"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Education Qualification
                </label>
                <select
                  value={qualification}
                  disabled={courseDisable.length}
                  onChange={(e) => setQualification(e.target.value)}
                  className="outline-none bg-white rounded-md mb-3 px-3 py-1 border border-gray-300 focus:border-indigo-500"
                >
                  <option value="">Select Qualification</option>
                  <option value="Primary Education">Primary Education</option>
                  <option value="Secondary Education">
                    Secondary Education
                  </option>
                  <option value="High school">High school</option>
                  <option value="Diploma">Diploma</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Post Graduate">Post Graduate</option>
                  <option value="Phd /Higher Education">
                    Phd /Higher Education
                  </option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Warrant Valid till
                </label>
                <input
                  type="date"
                  value={valid}
                  disabled={courseDisable.length}
                  onChange={(e) => setWarrantValid(e.target.value)}
                  placeholder="Enter the Date"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>{" "}
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Warrant Copy
                </label>
                {uploadwarrant && (
                  <iframe
                    src={uploadwarrant}
                    width="100%"
                    height="100px"
                    title="PDF Viewer"
                  />
                )}
                {/* <input
                  type="file"
                  disabled={courseDisable.length}
                  name="avatar"
                  className="outline-none mb-3 mt-1 py-2 bg-slate-200 px-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(e) => setWarrantCopy(e.target.files[0])}
                /> */}
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Occupation/Employement
                </label>
                <select
                  value={occupation}
                  disabled={courseDisable.length}
                  onChange={(e) => setOccupation(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                >
                  <option value="">Select Occupation</option>
                  <option value="Salaries">Salaries</option>
                  <option value="Business">Business</option>
                </select>
              </div>
              <div className="flex flex-row gap-10 -mt-8">
                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium text-black">
                    Any Special Interest/Skill
                  </label>
                  <textarea
                    type="text"
                    value={skill}
                    disabled={courseDisable.length}
                    onChange={(e) => setSkill(e.target.value)}
                    placeholder="Enter the Skills"
                    className="outline-none bg-white rounded-md px-3 py-1 w-72 border border-gray-300 "
                  />
                </div>
                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium text-black">
                    Upload Pre-ALT Certificate
                  </label>

                  {prealtcertificate1 ? (
                    <iframe
                      src={prealtcertificate1}
                      width="100%"
                      height="150px"
                      title="PDF Viewer"
                    />
                  ) : (
                    <></>
                  )}
                  <input
                    type="file"
                    accept="application/pdf"
                    disabled={courseDisable.length}
                    onChange={(e) => setPreAltCertificate(e.target.files[0])}
                    placeholder="Enter the Permanent Address"
                    className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 "
                  />
                </div>
              </div>
            </div>
          </div>

          <h3 className="font-bold text-xl pl-5 "> Basic Details</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Wing
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Subwing
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    From Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    To Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Venue
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Leader
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Certificate Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Certificate Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {basic.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.wing}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.subwing}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.fromDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.toDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.venue}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.leader}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.certificateNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.certificateDate}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h3 className="font-bold text-xl pl-5"> Advance Details</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Wing
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Subwing
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    From Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    To Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Venue
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Leader
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Certificate Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Certificate Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {advance.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.wing}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.subwing}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.fromDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.toDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.venue}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.leader}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.certificateNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.certificateDate}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <h3 className="font-bold text-xl pl-5"> Hwb Details</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                    Wing
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                    Subwing
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                    From Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                    To Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                    Venue
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                    Parchment Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                    Parchment Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                    Certificate Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ">
                    Certificate Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {hwb.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.wing}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.subwing}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.fromDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.toDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.venue}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.parchmentNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.parchmentDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.certificateNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.certificateDate}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="max-w-5xl mx-auto">
            <div className="font-medium text-2xl mx-10 mt-20 ">
              Training Courses Assisted after Pre-ALT
            </div>

            <form className="mt-8 space-y-6">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 table-auto">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name of the Course
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        From Date
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        To Date
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type of work done
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        No. of Participants
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Leader of the Course
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Upload Certificate
                      </th>
                      <th className="px-4 py-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {forms.map((item, index) => (
                      <tr key={item.id} className="border-b border-gray-300">
                        <td className="px-4 py-2">
                          <input
                            type="text"
                            placeholder="Enter the Course"
                            value={item.course}
                            disabled={
                              index < courseDisable.length &&
                              courseDisable[index]
                            }
                            onChange={(e) =>
                              handleChange(index, "course", e.target.value)
                            }
                            className="outline-none mt-1 py-2 px-2 block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="date"
                            value={item.fromDate}
                            disabled={
                              index < courseDisable.length &&
                              courseDisable[index]
                            }
                            onChange={(e) =>
                              handleChange(index, "fromDate", e.target.value)
                            }
                            className="outline-none mt-1 py-2 px-2 block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="date"
                            value={item.toDate}
                            disabled={
                              index < courseDisable.length &&
                              courseDisable[index]
                            }
                            onChange={(e) =>
                              handleChange(index, "toDate", e.target.value)
                            }
                            className="outline-none mt-1 py-2 px-2 block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="text"
                            placeholder="Enter the Details"
                            value={item.work}
                            disabled={
                              index < courseDisable.length &&
                              courseDisable[index]
                            }
                            onChange={(e) =>
                              handleChange(index, "work", e.target.value)
                            }
                            className="outline-none mt-1 py-2 px-2 block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="text"
                            value={item.participant}
                            placeholder="Enter the No. of Participant"
                            disabled={
                              index < courseDisable.length &&
                              courseDisable[index]
                            }
                            onChange={(e) =>
                              handleChange(index, "participant", e.target.value)
                            }
                            className="outline-none mt-1 py-2 px-2 block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </td>
                        <td className="px-4 py-2">
                          <input
                            type="text"
                            placeholder="Enter the Leader Name"
                            value={item.leader}
                            disabled={
                              index < courseDisable.length &&
                              courseDisable[index]
                            }
                            onChange={(e) =>
                              handleChange(index, "leader", e.target.value)
                            }
                            className="outline-none mt-1 py-2 px-2 block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </td>
                        <td className="px-4 py-1">
                          {item.uploadcertificate1 && (
                            <iframe
                              src={item.uploadcertificate1}
                              width="100%"
                              height="150px"
                              title="PDF Viewer"
                            ></iframe>
                          )}
                          <input
                            type="file"
                            name="uploadcertificate"
                            disabled={
                              index < courseDisable.length &&
                              courseDisable[index]
                            }
                            onChange={(e) =>
                              handleChange(
                                index,
                                "uploadcertificate",
                                e.target.files[0]
                              )
                            }
                            className="mt-1 py-2 px-2 block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </td>
                        <td className="px-4 py-2 text-right">
                          <button
                            type="button"
                            onClick={() => {
                              const newForms = [...forms]; // Create a shallow copy of the array
                              newForms.splice(index, 1); // Remove the item at the specified index
                              setForms(newForms); // Update the state with the new array
                            }}
                            className="text-red-600 hover:text-red-700"
                          >
                            <svg
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col md:flex-row md:justify-between mt-4 space-y-2 md:space-y-0">
                <button
                  type="button"
                  onClick={addMore}
                  className="py-2 px-4 border border-transparent text-sm font-bold rounded-md text-white bg-[#1D56A5] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add More
                </button>
              </div>
            </form>
          </div>

          <div
            className="bg-[#1D56A5] rounded-md flex justify-center items-center lg:mx-80 py-1 text-white font-medium my-5 cursor-pointer"
            onClick={handleSubmit}
          >
            <ToastContainer />
            Save
          </div>

          <div className="flex flex-col m-10">
            <div className="font-medium">
              Form1:Application for Course for Assistant Leader Trainers (SW)
            </div>
            <input
              type="file"
              name="form1"
              onChange={(e) => {
                setForm1(e.target.files[0]);
              }}
            />

            <div className="font-medium">
              Form 2 : Recommendation for appointment as Assistant Leader
              Trainer (Scout Wing)
            </div>
            <input
              type="file"
              name="form2"
              onChange={(e) => {
                setForm2(e.target.files[0]);
              }}
            />

            <div
              className="bg-[#1D56A5] mt-5 p-1 text-white rounded-sm w-36 cursor-pointer"
              // onClick={onSubmitData}
            >
              Submit Application
            </div>
          </div>

          {/* <div
            className="bg-[#1D56A5] rounded-md flex justify-center items-center lg:mx-80 py-1 text-white font-medium my-5 cursor-pointer"
            onClick={() => {
              navigate("/form2");
            }}
          >
            Next
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
