import React, { useState, useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { BASE_URL } from "../constant/constant";

const Carousel = () => {
  const [banner, setBanner] = useState([]);

  const getBanner = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);
      const response = await fetch(
        `${BASE_URL}/api/v1/getcarousel/${userId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch banner images");
      }
      const data = await response.json();
      setBanner(data);
    } catch (error) {
      console.error("Error fetching banner:", error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <>

      <div className=" max-w-4xl w-full ">
        <Slide
          duration={3000}
          transitionDuration={600}
          indicators={false}
          prevArrow={
            <FaChevronLeft className="text-4xl text-yellow-300 bg-black bg-opacity-50 rounded-full p-2 cursor-pointer ml-5" />
          }
          nextArrow={
            <FaChevronRight className="text-4xl text-yellow-300 bg-black bg-opacity-50 rounded-full p-2 cursor-pointer mr-5" />
          }
          indicatorStyle={{ marginBottom: "10px" }}
        >
          {banner.map((slideImage, index) => (
            <div
              key={index}
              className="relative h-72 overflow-hidden shadow-lg"
            >
              <div
                className="flex items-center justify-center h-full bg-cover bg-center bg-no-repeat transform transition-transform duration-700 ease-in-out hover:scale-105"
                style={{
                  backgroundImage: `url("${BASE_URL}/uploads/${slideImage.filename}")`,
                }}
              ></div>
            </div>
          ))}
        </Slide>
      </div>


    </>
  );
};

export default Carousel;
