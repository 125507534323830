import React, { useEffect, useState } from "react";
import LogoImg from "../../img/big logo (1).png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_USER_REGISTER, POST_USER_lOGIN } from "../../constant/constant";
import Swal from "sweetalert2";
import image from "../../img/img.jpg";
import imagebg from "../../img/login.png";
const Login = () => {
  const [isNewUser, setIsNewUser] = useState(false);
  const [bsgUid, setBsgUid] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedDob = dob.split("-").reverse().join("-");

    const data = {
      bsgUid: bsgUid,
      dob: formattedDob,
      email: email,
    };

    Swal.fire({
      title: "Processing...",
      text: "Please wait while we process your request.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      let response;
      if (isNewUser) {
        response = await axios.post(POST_USER_REGISTER, data);
        const _id = response.data._id;
        const bsgUid = response.data.bsgUid;
        const idString = JSON.stringify(_id);
        localStorage.setItem("_id", idString);
        localStorage.setItem("bsgUid", bsgUid);
        Swal.close();

        toast.success("User registered successfully");
        navigate("/");
      } else {
        response = await axios.post(POST_USER_lOGIN, data);

        const token = response.data.token;
        localStorage.setItem("authToken", token);
        localStorage.setItem("bsgUid", bsgUid);

        Cookies.set("token", token, { expires: 7 });

        Swal.close();

        if (
          response.data.message ===
          "First-time login. Please set your password."
        ) {
          navigate("/setpassword");
        } else {
          toast.success("Logged in successfully");
          navigate("/home");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.close();
      if (
        error.response?.data?.message ===
        "User already exists. Please login with BsgUid and password."
      ) {
        setTimeout(() => {
          navigate("/newuserlogin");
        }, 4000);

        toast.error("User already exists, Please Login with BsgUid & password");
      } else if (error.response?.data?.message === "User not found") {
        toast.error("User not found");
      } else if (
        error.response?.data?.message ===
        "Password is required for subsequent logins."
      ) {
        toast.error("Password is required for subsequent logins.");
      } else if (error.response?.data?.error === "User already exists") {
        toast.error("User already exists");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const toggleUserMode = () => {
    setIsNewUser(!isNewUser);
    setBsgUid("");
    setDob("");
    setEmail("");
  };

  const checkTokenValidity = async () => {
    try {
      const token = localStorage.getItem("userToken");
      console.log(token, "token");
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

        if (decodedToken.exp < currentTime) {
          // Token has expired
          navigate("/newuserlogin"); // Assuming you want to redirect to the login page
        } else {
          // Token is valid, navigate to dashboard
          navigate("/home");
        }
      }
    } catch (error) {
      // Handle any errors that might occur during token retrieval
      console.error("Error while checking token validity:", error);
    }
  };

  useEffect(() => {
    checkTokenValidity();
  }, [navigate]);

  return (
    <>
      <div
        className="h-screen w-screen flex items-center justify-center bg-cover bg-center relative "
        style={{ backgroundImage: `url(${imagebg})` }}
      >
        <ToastContainer />
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2">
        <div>
          <img
            src={image}
            className=" hidden lg:block h-[505px] max-w-md w-full shadow-xl rounded-l-lg"
          />
        </div>
        <div className="p-6  rounded-md lg:rounded-r-lg bg-slate-200 max-w-md w-full ">
          <img src={LogoImg} alt="Logo" className="h-8" />
          <h2 className="text-lg font-bold text-center mb-5 my-8 ">
            {isNewUser
              ? "Sign Up and Step Into the Future!"
              : "Welcome Back ,Please Login To Your Account "}
          </h2>

          <form onSubmit={handleSubmit}>
            {!isNewUser && (
              <>
                <div className="mb-3">
                  <label
                    htmlFor="bsgUid"
                    className="block text-sm font-medium text-black"
                  >
                    BSG UID Number
                  </label>
                  <input
                    type="text"
                    id="bsgUid"
                    className=" py-1 px-2 outline-none mt-1  w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="BSG UID Number"
                    value={bsgUid}
                    onChange={(e) => setBsgUid(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="block text-sm font-medium text-black">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    id="dob"
                    className=" px-2 py-1 outline-none mt-1  w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-black"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className=" px-2 py-1 outline-none mt-1  w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </>
            )}

            {isNewUser && (
              <>
                <div className="mb-3">
                  <label
                    htmlFor="bsgUid"
                    className="block text-sm font-medium text-black"
                  >
                    BSG UID Number
                  </label>
                  <input
                    type="text"
                    id="bsgUid"
                    className="py-1 px-2 outline-none mt-1 w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="BSG UID Number"
                    value={bsgUid}
                    onChange={(e) => setBsgUid(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-black"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className=" px-2 py-1 outline-none mt-1  w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="newpassword"
                    className="block text-sm font-medium text-black"
                  >
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    className=" px-2 py-1 outline-none mt-1  w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    required
                  />
                </div>
              </>
            )}

            <button
              type="submit"
              className="w-full bg-[#0C2D48] text-white py-1 px-4 rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 "
            >
              {isNewUser ? "Register" : "Login"}
            </button>
          </form>

          <div className="mt-10 text-center">
            <button
              className="text-[#1D56A5] hover:text-indigo-700 text-sm focus:outline-none"
              onClick={toggleUserMode}
            >
              {isNewUser
                ? "Existing User? Login Here"
                : "New User? Register Here"}
            </button>
          </div>
          
        </div>
        </div>
      </div>
    </>
  );
};

export default Login;
