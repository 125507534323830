import React from "react";
import jsPDF from 'jspdf';

const Download = (props) => {

  console.log(props,"pzbvx")

  const handlePrint = () => {
   
    const doc = new jsPDF();

    // Add text to the PDF
    doc.text('Hello world!', 10, 10);

    // Save the PDF
    doc.save('sample.pdf');
  };
  return (
    <>
      <button
        onClick={handlePrint}
        className="bg-[#1D56A5] rounded-md px-3 py-1.5 text-white"
      >
        Download
      </button>
    </>
  );
};

export default Download;


// import React from 'react';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

// const PdfGenerator = (props) => {

//   console.log(props.formData1,"formData1");
//   const handleDownloadPdf = () => {
//     const input = document.getElementById('pdfContent');
//     html2canvas(input)
//       .then((canvas) => {
//         const imgData = canvas.toDataURL('image/png');
//         const pdf = new jsPDF('p', 'mm', 'a4');
//         pdf.addImage(imgData, 'PNG', 10, 10);
//         pdf.save(`${formData1.name}_Details.pdf`);
//       });
//   };

//   return (
//     <div>
//       <div id="pdfContent" style={{ padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '10px' }}>
//         <h1 style={{ color: '#2c3e50' }}>User Details</h1>
//         <p><strong>Name:</strong> {formData1.name}</p>
//         <p><strong>Email:</strong> {formData1.email}</p>
//         <p><strong>DOB:</strong> {formData1.dob}</p>
//         <p><strong>BSG UID:</strong> {formData1.bsgUid}</p>
//         <p><strong>BSG Date:</strong> {formData1.bsgDate}</p>
//         <p><strong>Progress:</strong> {formData1.progress}%</p>
//         <p><strong>Training Course:</strong></p>
//         <ul>
//           {formData1.TrainingCourse.map((course, index) => (
//             <li key={index}>
//               <p><strong>Course:</strong> {course.course}</p>
//               <p><strong>From Date:</strong> {course.fromDate}</p>
//               <p><strong>To Date:</strong> {course.toDate}</p>
//               <p><strong>Work:</strong> {course.work}</p>
//               <p><strong>Leader:</strong> {course.leader}</p>
//               <p><strong>Participant:</strong> {course.participant}</p>
//             </li>
//           ))}
//         </ul>
//         <p><strong>Created At:</strong> {new Date(formData1.createdAt).toLocaleString()}</p>
//         <p><strong>Updated At:</strong> {new Date(formData1.updatedAt).toLocaleString()}</p>
//       </div>
//       <button
//         onClick={handleDownloadPdf}
//         style={{
//           marginTop: '20px',
//           padding: '10px 20px',
//           backgroundColor: '#3498db',
//           color: '#fff',
//           border: 'none',
//           borderRadius: '5px',
//           cursor: 'pointer'
//         }}
//       >
//         Download PDF
//       </button>
//     </div>
//   );
// };

// export default PdfGenerator;
