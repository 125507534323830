import React, { useState } from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiSolidUser } from "react-icons/bi";
import { SiFoursquarecityguide } from "react-icons/si";
import { IoChevronDownOutline } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import MenuIcon from "@mui/icons-material/Menu";
import BasicCourse from "./Course/BasicCourse";
import AdvanceCourse from "./Course/AdvanceCourse";
import { SiFormstack } from "react-icons/si";
import HWB from "./Course/HWB";
import PersonalInformation from "./PersonalInformation";
import Home from "./Home";
import image from "../img/logo3.png";
import { FcAbout } from "react-icons/fc";
import { FiLogOut } from "react-icons/fi";
import {
  MdHome,
  MdContactPage,
  MdBookmarkBorder,
  MdOutlineFavoriteBorder,
} from "react-icons/md";
import PreALTCourse from "./Course/ALTCourseForm1";
import Form2 from "./Course/ALTCourseForm2";
import Carousel from "../component/Carousel";
import Marquee from "./Marque";
import { HiMiniForward } from "react-icons/hi2";
import PreAltApplicationForm from "./Course/PreAltApplicationForm";


const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove cookies and local storage items
    Cookies.remove("token");
    localStorage.removeItem("authToken");
    localStorage.removeItem("userToken");
    localStorage.removeItem("bsgUid");
    localStorage.removeItem("id");
    localStorage.removeItem("_id");

    // Show toast notification for logout
    toast.info("Logged out successfully.");

    // Delay navigation by 3 seconds
    setTimeout(() => {
      navigate("/newuserlogin", { replace: true });
    }, 3000);
  };

  return (
    <>
      <ToastContainer />
      <div className="sm:hidden  bg-[#1D56A5] h-auto w-screen z-10 absolute top-0">
        <div className="flex justify-between items-center p-2 mx-10">
         
          <div className="flex justify-start items-center ">
            <img src={image} alt="Logo" className="h-10  " />
          </div>
          <MenuIcon
            className="text-white cursor-pointer"
            onClick={() => setShow(!show)}
          />
        </div>
        {show && (
          <div
            className="bg-white text-black absolute top-14 left-0 right-0 z-50 "
            style={{ height: "1800px" }}
          >
            <Link
              to="/home"
              className="flex items-center p-3 hover:bg-gray-200"
              onClick={() => setShow(false)}
            >
              <FaHome className="text-xl" />
              <span className="pl-2   font-semibold">Home</span>
            </Link>
            <Link
              to="/personal"
              className="flex items-center p-3 hover:bg-gray-200"
              onClick={() => setShow(false)}
            >
              <BiSolidUser className="text-2xl" />
              <span className="pl-2  font-semibold">
                Personal Information
              </span>
            </Link>
            <Link
              to="/basic"
              className="flex items-center p-3 hover:bg-gray-200"
              onClick={() => setShow(false)}
            >
              <SiFoursquarecityguide className="text-2xl" />
              <span className="pl-2  font-semibold">
                Basic Course
              </span>
            </Link>
            <Link
              to="/advance"
              className="flex items-center p-3 hover:bg-gray-200"
              onClick={() => setShow(false)}
            >
              <SiFoursquarecityguide className="text-2xl" />
              <span className="pl-2  font-semibold">
                Advance Course
              </span>
            </Link>
            <Link
              to="/hwb"
              className="flex items-center p-3 hover:bg-gray-200"
              onClick={() => setShow(false)}
            >
              <SiFoursquarecityguide className="text-2xl" />
              <span className="pl-2  font-semibold">HWB Course</span>
            </Link>
            <Link
              to="/altcourse"
              className="flex items-center p-3 hover:bg-gray-200"
              onClick={() => setShow(false)}
            >
              <SiFormstack className="text-2xl" />
              <span className="pl-2  font-semibold">
                ALT Course(Form-1)
              </span>
            </Link>
            <Link
              to="/form2"
              className="flex items-center p-3 hover:bg-gray-200"
              onClick={() => setShow(false)}
            >
              <SiFormstack className="text-2xl" />
              <span className="pl-2  font-semibold">
                ALT Course(Form-2)
              </span>
            </Link>
            <div className="flex items-center p-3 hover:bg-gray-200 cursor-pointer">
              <FiLogOut className="text-2xl cursor-pointer" />
              <span
                className="pl-2  font-semibold"
                onClick={handleLogout}
              >
                Logout
              </span>
            </div>
          </div>
        )}
      </div>

    
    



      <div className="md:flex md:min-h-screen ">
        <div className="  md:w-64 hidden md:block">
          {/* <div className="p-5 flex justify-center">
            <Link to="/home">
              <img src={image} alt="Logo" className="py-8 " />
            </Link>
          </div> */}
          <div className="mb-6 w-64 ">
            <h3 className="mx-6  my-2 text-xs text-gray-400 uppercase tracking-widest">
              USER
            </h3>
            <Link
              to="/home"
              className="flex items-center px-3 py-2 mx-1 hover:bg-gray-300 hover:text-black bg-[#1D56A5] text-white rounded-sm mb-2"
            >
              <HiMiniForward className="text-lg hover:text-black" />
              <span className="pl-3">Home</span>
            </Link>
            <Link
              to="/personal"
              className="flex items-center px-3 py-2 mx-1 hover:bg-gray-300 hover:text-black bg-[#1D56A5] text-white rounded-sm mb-2"
            >
              <HiMiniForward className="text-lg hover:text-black" />
              <span className="pl-3">Personal Information</span>
            </Link>
            <div
              className="flex items-center px-3 py-2 mx-1 cursor-pointer hover:bg-gray-300 hover:text-black bg-[#1D56A5] text-white rounded-sm mb-2"
              onClick={() => setIsOpen(!isOpen)}
            >
              <HiMiniForward className="text-lg hover:text-black" />
              <span className="pl-3 flex-1">Training Information</span>
              <IoChevronDownOutline className="text-lg" />
            </div>
            {isOpen && (
              <div className="ml-6">
                <Link
                  to="/basic"
                  className="block px-3 py-2 hover:bg-gray-300 hover:text-black bg-[#1D56A5] text-white rounded-sm mb-2"
                >
                  Basic Course
                </Link>
                <Link
                  to="/advance"
                  className="block px-3 py-2 hover:bg-gray-300 hover:text-black bg-[#1D56A5] text-white rounded-sm mb-2"
                >
                  Advance Course
                </Link>
                <Link
                  to="/hwb"
                  className="block px-3 py-2 hover:bg-gray-300 hover:text-black bg-[#1D56A5] text-white rounded-sm mb-2"
                >
                  HWB Course
                </Link>
              </div>
            )}

            <Link
              to="/prealt"
              className="flex items-center px-3 py-2 mx-1 hover:bg-gray-300 hover:text-black bg-[#1D56A5] text-white rounded-sm mb-2"
            >
              <HiMiniForward className="text-lg hover:text-black" />
              <span className="pl-3">PRE-ALT Application Form </span>
            </Link>
            <Link
              to="/altcourse"
              className="flex items-center px-3 py-2 mx-1 hover:bg-gray-300 hover:text-black bg-[#1D56A5] text-white rounded-sm mb-2"
            >
              <HiMiniForward className="text-lg hover:text-black" />
              <span className="pl-3">ALT Application Form</span>
            </Link>
            {/* <Link
              to="/form2"
              className="flex items-center px-3 py-2 mx-1 hover:bg-gray-300 hover:text-black bg-[#1D56A5] text-white rounded-sm mb-2"
            >
              <HiMiniForward className="text-lg hover:text-black" />
              <span className="pl-3">ALT Application Form (2)</span>
            </Link> */}
            <div
              className="flex items-center px-3 py-2 mx-1 hover:bg-gray-300 hover:text-black bg-[#1D56A5] text-white rounded-sm mb-2"
              onClick={handleLogout}
            >
              <HiMiniForward className="text-lg hover:text-black" />
              <span className="pl-3 cursor-pointer">Log Out</span>
            </div>
          </div>
        </div>

        <div className="flex-1 bg-white mt-10 ">
          <main className="p-6">
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/personal" element={<PersonalInformation />} />
              <Route path="/basic" element={<BasicCourse />} />
              <Route path="/advance" element={<AdvanceCourse />} />
              <Route path="/hwb" element={<HWB />} />
              <Route path="/altcourse" element={<PreALTCourse />} />
              <Route path="/form2" element={<Form2 />} />
              <Route path="/carousel" element={<Carousel />} />
              <Route path="/prealt" element={<PreAltApplicationForm />} />
        
            </Routes>
          </main>
        </div>
      </div>




      
 
    </>
  );
};

export default Sidebar;
