import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../constant/constant";
import ProgressBar from "@ramonak/react-progress-bar";
import Download from "../Download";

const Form2 = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [groupregisterform, setGroupRegisterForm] = useState("");
  const [dob, setDob] = useState("");
  const [bsgUid, setBsgUid] = useState("");
  // const [register, setRegister] = useState("");
  const [state, setState] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [group, setGroup] = useState("");
  const [parchmentnumber, setParchmentNumber] = useState("");
  const [parchmentdate, setParchmentDate] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [revenuePincode, setRevenuePincode] = useState("");
  const [uploadPhoto, setUploadPhoto] = useState(null);
  const [warrantNumber, setWarrantNumber] = useState("");
  const [warrantvalid, setWarrantValid] = useState("");
  const [warrantDate, setWarrantDate] = useState("");
  const [valid, setValid] = useState("");
  const [uploadwarrant, setWarrantUpload] = useState(null);
  const [occupation, setOccupation] = useState("");
  const [qualification, setQualification] = useState("");
  const [place, setPlace] = useState("");
  const [completedhwb, setCompletedhwb] = useState("");
  const [courseDisable, setCourseDisable] = useState([]);
  const [personalDisable, setPersonalDisable] = useState([]);
  const [age, setAge] = useState("");
  const [deatails, setDetails] = useState("");
  const [service, setService] = useState("");
  const [book, setBook] = useState("");
  const [listofbook, setListofbook] = useState("");
  const [subject, setSubject] = useState("");
  const [rank, setRank] = useState("");
  const [certificate, setCertificate] = useState("");
  const [progress, setProgress] = useState(50);
  const [form1, setForm1] = useState("");
  const [form2, setForm2] = useState("");
  const [prealtcertificate, setPreAltCertificate] = useState("");
  const [prealtcertificate1, setPreAltCertificate1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState("");

  const getProgressLabel = () => {
    if (progress === 0) {
      return "Form1";
    } else if (progress === 50) {
      return "Form2";
    } else if (progress === 100) {
      return "Completed";
    }
    return "";
  };

  const [forms, setForms] = useState([
    {
      course: "",
      fromDate: "",
      toDate: "",
      work: "",
      participant: "",
      leader: "",
      uploadcertificate: "",
      uploadcertificate1: "null",
    },
  ]);

  const addMore = () => {
    const newForm = {
      course: "",
      participant: "",
      fromDate: "",
      toDate: "",
      work: "",
      leader: "",
      uploadcertificate: "",
     
    };
    setForms([...forms, newForm]);
  };

  const handleChange = (index, field, value) => {
    const updatedCourses = [...forms];
    updatedCourses[index][field] = value;
    setForms(updatedCourses);
  };

  const validateForm = () => {
    // Check if any course has empty fields
    return forms.every(
      (course) =>
        course.course &&
        course.participant &&
        course.fromDate &&
        course.toDate &&
        course.work &&
        course.leader &&
        course.uploadcertificate
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmptyField=
      name === "" || 
      email === "" || 
      groupregisterform === "" || 
      dob === "" || 
      bsgUid === "" || 
      state === "" || 
      mobileNumber === "" ||
      group === "" || 
      parchmentnumber === "" || 
      parchmentdate === "" || 
      // permanentAddress === "" || 
      revenuePincode === "" || 
      warrantNumber === "" || 
      warrantvalid === "" || 
      warrantDate === "" || 
      valid === "" || 
      occupation === "" || 
      qualification === "" || 
      // place === "" || 
      // completedhwb === "" || 
      age === "" 
      // deatails === "" || 
      // service === "" || 
      // book === "" || 
      // listofbook === "" || 
      // subject === "" || 
      // rank === "" || 
      // certificate === "" 
      // form1 === "" || 
      // form2 === "" || 
      // (prealtcertificate === "" && prealtcertificate1 === "") || 
      // (uploadPhoto === null) || 
      // (uploadwarrant === null) 
      // isSubmitted === ""
    
    if (isEmptyField || !validateForm()) {
      toast.error("Please fill out all fields before submitting.");
      return 
    }
    

    const progress2 = 100;
    const formData = new FormData();
    const formDetails = [];
    for (let i = 0; i < forms.length; i++) {
      const formObject = {
        course: forms[i].course,
        fromDate: forms[i].fromDate,
        toDate: forms[i].toDate,
        work: forms[i].work,
        leader: forms[i].leader,
        participant: forms[i].participant,
      };

      if (forms[i].uploadcertificate) {
        formObject.uploadcertificate = `uploadcertificate_${i}`;

        formData.append(`uploadcertificate_${i}`, forms[i].uploadcertificate);
      }
      formDetails.push(formObject);
    }

    formData.append("service", service);
    formData.append("age", age);
    formData.append("deatails", deatails);
    formData.append("certificate", certificate);
    formData.append("rank", rank);
    formData.append("subject", subject);
    formData.append("completedhwb", completedhwb);
    formData.append("book", book);
    formData.append("listofbook", listofbook);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("dob", dob);
    formData.append("bsgUid", bsgUid);
    formData.append("state", state);
    formData.append("mobileNumber", mobileNumber);
    formData.append("permanentAddress", permanentAddress);
    formData.append("warrantvalid", warrantvalid);
    formData.append("warrantDate", warrantDate);
    formData.append("warrantNumber", warrantNumber);
    formData.append("prealtcertificate", prealtcertificate);
    formData.append("parchmentdate", parchmentdate);
    formData.append("parchmentnumber", parchmentnumber);
    formData.append("progress", progress2);

    formData.forEach((value, key) => {
      console.log(key, value);
    });

    if (uploadPhoto) {
      formData.append("uploadPhoto", uploadPhoto);
    }
    if (uploadwarrant) {
      formData.append("uploadWarrant", uploadwarrant);
    }
    formData.append("occupation", occupation);
    formData.append("qualification", qualification);
    formData.append("place", place);
    formData.append("training", JSON.stringify(formDetails));

    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);

      if (isSubmitted) {
        toast("Form is already submitted");
        return;
      }
      const response = await axios.post(
        `${BASE_URL}/api/v1/altapplicationform2Details/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data, "response");
      const responseMessage1 = response.data._id;
      console.log(responseMessage1, "responseMessage");
      localStorage.setItem("id", responseMessage1);
      toast.success("ALT Form2 Submitted Successfully");
      setProgress(100);
    } catch (error) {
      console.error("Error:", error);
      toast("An error occurred during registration");
    }
  };

  useEffect(() => {
    getDataa();
  }, []);

  const getDataa = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);
      const response = await axios.get(
        `${BASE_URL}/api/v1/personaldetails/${userId}`
      );

      const email = response.data.email;
      const formattedDob = response?.data?.dob.split("-").reverse().join("-");

      setDob(formattedDob);
      calculateAge(formattedDob);
      const bsgUidd = response.data.bsgUid;
      setEmail(email);
      setBsgUid(bsgUidd);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
    getHwbDetail();
  }, []);

  const getHwbDetail = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);

      const response = await axios.get(
        `${BASE_URL}/api/v1/hwbDetails/${userId}`
      );

      const hwbDetails = response.data;
      console.log(hwbDetails, "hwbDetails");

      const parchmentDates = hwbDetails.map((item) => item.parchmentDate);
      console.log(parchmentDates, "parchmentDates");
      const parchmentNumber = hwbDetails.map((item) => item.parchmentNumber);
      console.log(parchmentNumber, "parchmentMumber");

      setParchmentDate(parchmentDates);
      setParchmentNumber(parchmentNumber);
    } catch (error) {
      toast.error("Error ");
    }
  };

  const getData = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);
      console.log(userId, "storedId");
      const response = await axios.get(
        `${BASE_URL}/api/v1/personaldetails/${userId}`
      );
      console.log(response.data, "PersonalDetails");
      const personalDetails = response.data;

      if (personalDetails) {
        setPersonalDisable(new Array(personalDetails.length).fill(true));

        setName(personalDetails.name);
        setEmail(personalDetails.email);
        setBsgUid(personalDetails.bsgUid);
        setState(personalDetails.state);
        setPermanentAddress(personalDetails.permanentAddress);
        setMobileNumber(personalDetails.mobileNumber);
        setDob(personalDetails.dob);
        calculateAge(personalDetails.dob);
        setRevenuePincode(personalDetails.revenuePincode);
        setWarrantNumber(personalDetails.warrantNumber);
        setWarrantDate(personalDetails.warrantDate);
        setWarrantValid(personalDetails.valid);
        setWarrantUpload(personalDetails.uploadwarrant);
        setWarrantDate(personalDetails.warrantDate);
        setOccupation(personalDetails.occupation);
        setUploadPhoto(personalDetails.uploadPhoto);
        setValid(personalDetails.valid);
        setOccupation(personalDetails.occupation);
        setGroup(personalDetails.group);
        setGroupRegisterForm(personalDetails.groupregisterform);
        setPlace(personalDetails.place);
        setQualification(personalDetails.qualification);
        setState(personalDetails.state);
        
      }
    } catch (error) {
      console.error("Error fetching personal details:", error);
    }
  };

  function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    console.log(age, "age");
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Check if the current month/day is before the birth month/day
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setAge(age)
    return age;
  };
  useEffect(()=>{
    calculateAge()
  },[])

  const handleCalculateAge = (event) => {
    const dobValue = event.target.value;
    console.log(dobValue, "docValue");
    setDob(dobValue);
    if (dobValue) {
      const calculatedAge = calculateAge(dobValue);
      setAge(calculatedAge);
    } else {
      setAge("");
    }
  };

  const onSubmitData = async (e) => {
    e.preventDefault();
    if (!form1 || !form2) {
      console.error("Form data is missing");
      toast.error("Form data is missing");
      return;
    }
    const formData = new FormData();
    formData.append("form1", form1);
    formData.append("form2", form2);

    console.log("FormData entries:");
    formData.forEach((value, key) => {
      console.log(key, value);
    });

    try {
      const storedIdString = localStorage.getItem("_id");
      const userId = JSON.parse(storedIdString);
      console.log(userId, "storedId");
      const response = await axios.post(
        `${BASE_URL}/api/v1/formsubmission/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data, "response");

      toast.success(" Form Submitted Successfully");
    } catch (error) {
      console.error("Error:", error);
      toast("An error occurred during Submission");
    }
  };

  useEffect(() => {
    getAllByAlt();
  }, []);
  const getAllByAlt = async () => {
    try {
      const storedIdString = localStorage.getItem("_id");
      if (storedIdString) {
        const userId = JSON.parse(storedIdString);
        const response = await axios.get(
          `${BASE_URL}/api/v1/getaltapplicationform2Details/${userId}`
        );
        console.log(response.data, "all form2 details");

        const getAllalt = response.data;
        const getAllaltTrainingCourse = response.data.TrainingCourse;
        console.log(getAllaltTrainingCourse, "dsfsbjfh");

        const altTrainingCourse = getAllaltTrainingCourse.map((forms) => ({
          course: forms.course,
          fromDate: forms.fromDate,
          toDate: forms.toDate,
          work: forms.work,
          venue: forms.venue,
          leader: forms.leader,
          participant: forms.participant,
          uploadcertificate1: forms.uploadcertificate,
        }));
        console.log(altTrainingCourse, "altTrainingCourse");

        if (altTrainingCourse[0]) {
          setCourseDisable(new Array(altTrainingCourse.length).fill(true));
          setForms(altTrainingCourse);
        }

        if (getAllalt) {
          setRank(getAllalt.rank);
          setProgress(getAllalt.progress);
          setPreAltCertificate1(getAllalt.prealtcertificate);
          setIsSubmitted(getAllalt.isSubmitted);
          setCompletedhwb(getAllalt.completedhwb);
        } else {
          console.log("error");
        }
      } else {
        console.error("No user ID found in local storage.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
     <div className="bg-blue-100 border-l-4 border-blue-500 p-2 rounded-md shadow-lg -mt-4">
  <div className="flex items-start">
    <svg
      className="h-6 w-6 text-blue-500 mt-0.5 mr-2"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 16h-1v-4h-1m0-4h.01M12 4v4m-6 8h12v2H6v-2z"
      />
    </svg>
    <div className="text-blue-800">
      <span className="font-semibold text-red-600">Disclaimer:</span> 
      Once you submit this form, you will not be able to make any changes to the information provided. Please review your entries carefully before submitting, as this submission is final and cannot be edited or resubmitted.
    </div>
  </div>
</div>
      <div className=" text-center font-bold pt-8 text-3xl mb-5 ">
      ALT APPLICATION FORM (2)
      </div>

      <div className="flex justify-end text-end my-5">
        {isSubmitted ? <Download /> : <p></p>}
      </div>
      <div
        style={{ position: "relative", width: "100%", marginBottom: "20px" }}
      >
        <ProgressBar completed={progress} />
        <div className="font-medium">{getProgressLabel()}</div>
      </div>

      <div className="flex justify-center items-center mb-8">
        <div className="pt-5  w-full  rounded-lg shadow-md">
          <div className="px-8 ">

            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">Name</label>
                <input
                  type="text"
                  value={name}
                  disabled={personalDisable.length}
                  placeholder="Enter the Full Name"
                  onChange={(e) => setName(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-1 font-medium text-black">Email</label>
                <input
                  type="text"
                  value={email}
                  disabled={personalDisable.length}
                  placeholder="Enter the Email"
                  onChange={(e) => setEmail(e.target.value)}
                  className={`outline-none bg-white rounded-md px-3 py-1 border ${
                    isValidEmail(email) ? "border-gray-300" : "border-red-500"
                  } focus:border-indigo-500`}
                />
                {!isValidEmail(email) && (
                  <p className="text-red-500 text-sm mt-1">
                    Please enter a valid email address.
                  </p>
                )}
              </div>
          

            <div className="grid grid-cols-2 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Uploaded Photo
                </label>
                {uploadPhoto && (
                  <img
                    src={uploadPhoto}
                    alt="photo"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                )}
              </div>  </div>
            </div>

            <div className="grid grid-cols-4 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Mobile Number
                </label>
                <input
                  type="text"
                  value={mobileNumber}
                  disabled={personalDisable.length}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder="Enter the Mobile Number"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Home Address
                </label>
                <textarea
                  type="text"
                  value={permanentAddress}
                  disabled={personalDisable.length}
                  onChange={(e) => setPermanentAddress(e.target.value)}
                  placeholder="Enter the Permanent Address"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Name of the group
                </label>
                <textarea
                  type="text"
                  value={group}
                  disabled={personalDisable.length}
                  onChange={(e) => setGroup(e.target.value)}
                  placeholder="Enter the Permanent Address"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Registered Form
                </label>

                {groupregisterform && (
                  <iframe
                    src={groupregisterform}
                    width="100%"
                    height="150px"
                    title="PDF Viewer"
                  ></iframe>
                )}

                {/* <textarea
                  type="text"
                  value={register}
                  disabled={courseDisable.length}
                  onChange={(e) => setRegister(e.target.value)}
                  placeholder="Enter the Register "
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                /> */}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Completed HWB
                </label>
                <select
                  value={completedhwb}
                  disabled={courseDisable.length}
                  onChange={(e) => setCompletedhwb(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                >
                  <option value="">Select HWB</option>
                  <option value="HWB-Cub">HWB-Cub </option>
                  <option value="HWB-Scout">HWB-Scout </option>
                  <option value="HWB-Rover">HWB-Rover</option>
                </select>
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">BSGUID </label>
                <input
                  type="text"
                  value={bsgUid}
                  disabled={personalDisable.length}
                  placeholder="Enter the UID"
                  onChange={(e) => setBsgUid(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
           

         
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  HWB Parchment Number{" "}
                </label>
                <input
                  type="text"
                  value={parchmentnumber}
                  disabled={personalDisable.length}
                  placeholder="Enter the UID"
                  onChange={(e) => setParchmentNumber(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
           </div>

            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  HWB Parchment Date
                </label>
                <input
                  type="text"
                  value={parchmentdate}
                  disabled={personalDisable.length}
                  placeholder="Enter the UID"
                  onChange={(e) => setParchmentDate(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
       

              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">BSG State</label>
                <input
                  value={state}
                  disabled={personalDisable.length}
                  onChange={(e) => setState(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                ></input>
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">BSGUID </label>
                <input
                  type="text"
                  value={bsgUid}
                  disabled={personalDisable.length}
                  placeholder="Enter the UID"
                  onChange={(e) => setBsgUid(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <div className="flex flex-col mb-4">
                  <label className="mb-1 font-medium text-black">Age</label>

                  <input
                    type="text"
                    value={age}
                    placeholder="Age"
                    className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500 mt-2"
                  />
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">DOB</label>

                <input
                  type="date"
                  value={dob}
                  // onChange={handleCalculateAge}
                  placeholder="Enter your Date of Birth (YYYY-MM-DD)"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
         

     
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">Pincode</label>
                <input
                  type="text"
                  value={revenuePincode}
                  disabled={personalDisable.length}
                  onChange={(e) => setRevenuePincode(e.target.value)}
                  placeholder="Enter the Pincode"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div> 
              
            </div>
              
            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Upload Pre-ALT Certificate
                </label>
                {prealtcertificate1 && (
                  <iframe
                    src={prealtcertificate1}
                    width="100%"
                    height="150px"
                    title="PDF Viewer"
                  />
                )}
                <input
                  type="file"
                  disabled={courseDisable.length}
                  onChange={(e) => setPreAltCertificate(e.target.files[0])}
                  placeholder="Enter the Permanent Address"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
     

      
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Warrant Number
                </label>
                <input
                  type="text"
                  value={warrantNumber}
                  disabled={personalDisable.length}
                  onChange={(e) => setWarrantNumber(e.target.value)}
                  placeholder="Enter the Warrant Number"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Warrant Date
                </label>
                <input
                  type="date"
                  value={warrantDate}
                  disabled={personalDisable.length}
                  onChange={(e) => setWarrantDate(e.target.value)}
                  placeholder="Enter the Date"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10">
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Education Qualification
                </label>
                <select
                  value={qualification}
                  disabled={personalDisable.length}
                  onChange={(e) => setQualification(e.target.value)}
                  className="outline-none bg-white rounded-md mb-3 px-3 py-1 border border-gray-300 focus:border-indigo-500"
                >
                  <option value="">Select Qualification</option>
                  <option value="Primary Education">Primary Education</option>
                  <option value="Secondary Education">
                    Secondary Education
                  </option>
                  <option value="High school">High school</option>
                  <option value="Diploma">Diploma</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Post Graduate">Post Graduate</option>
                  <option value="Phd /Higher Education">
                    Phd /Higher Education
                  </option>
                </select>
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Warrant Valid till
                </label>
                <input
                  type="date"
                  value={warrantvalid}
                  disabled={personalDisable.length}
                  onChange={(e) => setWarrantValid(e.target.value)}
                  placeholder="Enter the Date"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
   

            <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Occupation/Employement
                </label>
                <select
                  value={occupation}
                  disabled={personalDisable.length}
                  onChange={(e) => setOccupation(e.target.value)}
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                >
                  <option value="">Select Occupation</option>
                  <option value="Salaries">Salaries</option>
                  <option value="Business">Business</option>
                </select>
              </div>   
           </div>

      
         
              <div className="flex flex-col mb-4">
                <label className="mb-1 font-medium text-black">
                  Current Rank/Position in the Movement
                </label>
                <input
                  type="text"
                  value={rank}
                  disabled={courseDisable.length}
                  onChange={(e) => setRank(e.target.value)}
                  placeholder="Enter the Rank"
                  className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
                />
              </div>
          
      
          

            <div className="font-medium text-2xl mx-10 mt-20 ">
              Training Courses Assisted after Pre-ALT
            </div>

            <form className="mt-8 space-y-6">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th>Name of the Course</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Type of work done</th>
                    <th>No. of Participants</th>
                    <th>Leader of the Course</th>
                    <th>Upload Certificate</th>
                  </tr>
                </thead>
                <tbody>
                  {forms.map((item, index) => (
                    <tr key={item.id} className="border-b border-gray-300">
                      <td>
                        <input
                          type="text"
                          value={item.course}
                          placeholder="Enter the Course "
                          disabled={
                            index < courseDisable.length && courseDisable[index]
                          }
                          onChange={(e) =>
                            handleChange(index, "course", e.target.value)
                          }
                          className="outline-none mt-1 py-2 bg-slate-200 px-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      
                      </td>
                      <td>
                        <input
                          type="date"
                          value={item.fromDate}
                            placeholder="Enter the Date"
                          disabled={
                            index < courseDisable.length && courseDisable[index]
                          }
                          onChange={(e) =>
                            handleChange(index, "fromDate", e.target.value)
                          }
                          className="outline-none mt-1 py-2 bg-slate-200 px-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          value={item.toDate}
                            placeholder="Enter the Date"
                          disabled={
                            index < courseDisable.length && courseDisable[index]
                          }
                          onChange={(e) =>
                            handleChange(index, "toDate", e.target.value)
                          }
                          className="outline-none mt-1 py-2 bg-slate-200 px-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={item.work}
                            placeholder="Enter the Work"
                          disabled={
                            index < courseDisable.length && courseDisable[index]
                          }
                          onChange={(e) =>
                            handleChange(index, "work", e.target.value)
                          }
                          className="mt-1 outline-none py-2 bg-slate-200 px-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={item.participant}
                            placeholder="Enter the No. of Participnt"
                          disabled={
                            index < courseDisable.length && courseDisable[index]
                          }
                          onChange={(e) =>
                            handleChange(index, "participant", e.target.value)
                          }
                          className="mt-1 outline-none py-2 bg-slate-200 px-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={item.leader}
                            placeholder="Enter the Leader Name"
                          disabled={
                            index < courseDisable.length && courseDisable[index]
                          }
                          onChange={(e) =>
                            handleChange(index, "leader", e.target.value)
                          }
                          className="mt-1 py-2 outline-none bg-slate-200 px-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </td>
                      <td className="px-4 py-2">
                        {item.uploadcertificate1 && (
                          <div className="mt-2">
                            <iframe
                              src={item.uploadcertificate1}
                              width="100%"
                              height="150px"
                              title="PDF Viewer"
                            ></iframe>
                          </div>
                        )}

                        <input
                          type="file"
                          name="uploadcertificate"
                          disabled={
                            index < courseDisable.length && courseDisable[index]
                          }
                          onChange={(e) =>
                            handleChange(
                              index,
                              "uploadcertificate",
                              e.target.files[0]
                            )
                          }
                          className="mt-1 py-2 px-2 block w-full bg-gray-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </td>

                      <td className="flex justify-end">
                        {forms.length > 1 && (
                          <button
                            type="button"
                            onClick={() => {
                              setForms(forms.splice(index, 1));
                            }}
                            className="text-red-600 hover:text-red-700"
                          >
                            <svg
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={addMore}
                  className="py-2 px-4 outline-none border border-transparent text-sm font-bold rounded-md text-white bg-[#1D56A5] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add More
                </button>
              </div>
            </form>
          </div>

          <div
            className="bg-[#1D56A5] rounded-md flex justify-center items-center lg:mx-80 py-1 text-white font-medium my-5 cursor-pointer"
            onClick={handleSubmit}
          >
            <ToastContainer />
            Save
          </div>

          <div className="flex flex-col m-10">
            <div className="font-medium">
              Form1:Application for Course for Assistant Leader Trainers (SW)
            </div>
            <input
              type="file"
              name="form1"
              onChange={(e) => {
                setForm1(e.target.files[0]);
              }}
            />

            <div className="font-medium">
              Form 2 : Recommendation for appointment as Assistant Leader
              Trainer (Scout Wing)
            </div>
            <input
              type="file"
              name="form2"
              onChange={(e) => {
                setForm2(e.target.files[0]);
              }}
            />

            <div
              className="bg-[#1D56A5] mt-5 p-1 text-white rounded-sm w-36 cursor-pointer"
              onClick={onSubmitData}
            >
              Submit Application
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form2;
