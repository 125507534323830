




import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../constant/constant";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const FormComponent = () => {
  const [courseState, setCourseState] = useState([]);
  const [formData, setFormData] = useState({
    wing: "",
    state: "",
    fullName: "",
    homeAddress: "",
    email: "",
    mobile: "",
    uid: "",
    occupation: "",
    employment: "",
    dob: "",
    education: "",
    scoutingBackground: "",
    section: "",
    details: "",
    rank: "",
    years: "",
    warrantNos: "",
    remarks: "",
    booksStudied: "",
    scoutSubject: "",
    courseType: "",
    fromDate: "",
    toDate: "",
    place: "",
    leader: "",
    fitnessStatement: false,
    fitnessCertificate: null,
    unitRunningStatement: false,
    unitCertificate: null,
    courseCertificate: null,
    selectedState: "", // Added selectedState to formData
  });

  // Fetch data from API to auto-fill fields
  useEffect(() => {
    async function fetchData() {
      const storedIdString = localStorage.getItem("_id");
    const userId = JSON.parse(storedIdString);
      const response = await fetch(
        `${BASE_URL}/api/v1/personaldetails/${userId}`
      );
      const data = await response.json();
      console.log(data, "data");
      setFormData((prevData) => ({
        ...prevData,
        state: data.state,
        fullName: data.name,
        homeAddress: data.permanentAddress,
        email: data.email,
        mobile: data.mobileNumber,
        uid: data.bsgUid,
        dob: data.dob,
        education: data.qualification,
      }));
    }
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const storedIdString = localStorage.getItem("_id");
    const userId = JSON.parse(storedIdString);
    console.log(userId,"userId")
    // Validation check for empty fields
    const {
      wing,
      state,
      fullName,
      homeAddress,
      email,
      mobile,
      uid,
      occupation,
      employment,
      dob,
      education,
      scoutingBackground,
      section,
      details,
      rank,
      years,
      warrantNos,
      remarks,
      booksStudied,
      scoutSubject,
      courseType,
      courseCertificate,
      fromDate,
      toDate,
      place,
      leader,
      fitnessStatement,
      fitnessCertificate,
      unitRunningStatement,
      unitCertificate,
      selectedState // Ensure selectedState is included
    } = formData;
console.log({selectedState,unitCertificate})
    if (
      !wing ||
      !state ||
      !fullName ||
      !homeAddress ||
      !email ||
      !mobile ||
      !uid ||
      !occupation ||
      !employment ||
      !dob ||
      !education ||
      !scoutingBackground ||
      !section ||
      !details ||
      !rank ||
      !years ||
      !warrantNos ||
      !remarks ||
      !booksStudied ||
      !scoutSubject ||
      !fromDate ||
      !toDate ||
      !place ||
      !leader ||
      !fitnessStatement ||
      !fitnessCertificate ||
      !unitRunningStatement ||
      !unitCertificate ||
      !courseCertificate ||
      !selectedState // Check for selectedState
    ) {
      toast.error("Please fill out all fields before submitting.");
      return;
    }

    // Create a FormData instance
    const submissionData = new FormData();

    // Append each field to FormData
    Object.entries(formData).forEach(([key, value]) => {
      submissionData.append(key, value);
    });

    try {
      // Make the POST request
      const response = await axios.post(
        `${BASE_URL}/api/v1/prealtAppilcationForm/${userId}`,
        submissionData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Log the response and notify success
      console.log(response.data, "response");
      toast.success("Form submitted successfully!");
    } catch (error) {
      // Handle any errors and notify user
      console.error("Error submitting form:", error);
      if (error.response && error.response.status === 409)
        toast.error("Data already exists.");
      } 
  
  };

  useEffect(() => {
    getState();
  }, []);

  const getState = async () => {
    try {
      const userId = "6721c3e5d7fde066bc186b49";
      const response = await axios.get(`${BASE_URL}/api/v1/getstate/${userId}`);
      console.log(response, "response");
      setCourseState(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0],
    }));
  };

  const selectstate = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      selectedState: value // Update selectedState in formData
    }));
    console.log(value);
  };

  return (
    <>
      <div className="flex justify-center font-bold text-black uppercase text-2xl mb-8">
        Pre-Alt Application Form
      </div>
      <div className="container mx-auto p-4">
        <ToastContainer />
        <form className="grid gap-4 grid-cols-1 md:grid-cols-3">
          {/* Wing */}
          <div className="flex flex-col">
            <label className="font-semibold">Wing</label>
            <select
              name="wing"
              value={formData.wing}
              onChange={handleInputChange}
              className="border rounded p-2"
            >
              <option value="">Select Wing</option>
              <option value="Scout">Scout Wing</option>
              <option value="Guide">Guide Wing</option>
            </select>
          </div>

          {/* Name of State (Auto-filled) */}
          <div className="flex flex-col">
            <label className="font-semibold">Name of State</label>
            <input
              type="text"
              name="state"
              value={formData.state}
              readOnly
              className="border rounded p-2 bg-gray-100"
            />
          </div>

          {/* Full Name (Auto-filled) */}
          <div className="flex flex-col">
            <label className="font-semibold">Full Name (In Block Letters)</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              readOnly
              className="border rounded p-2 bg-gray-100"
            />
          </div>

          {/* Full Home Address (Auto-filled) */}
          <div className="flex flex-col">
            <label className="font-semibold">Full Home Address</label>
            <input
              type="text"
              name="homeAddress"
              value={formData.homeAddress}
              readOnly
              className="border rounded p-2 bg-gray-100"
            />
          </div>

          {/* Email (Auto-filled) */}
          <div className="flex flex-col">
            <label className="font-semibold">E-mail</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              readOnly
              className="border rounded p-2 bg-gray-100"
            />
          </div>

          {/* Mobile (Auto-filled) */}
          <div className="flex flex-col">
            <label className="font-semibold">Mobile</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              readOnly
              className="border rounded p-2 bg-gray-100"
            />
          </div>

          {/* UID (Auto-filled) */}
          <div className="flex flex-col">
            <label className="font-semibold">UID</label>
            <input
              type="text"
              name="uid"
              value={formData.uid}
              readOnly
              className="border rounded p-2 bg-gray-100"
            />
          </div>

          {/* Occupation / Employment */}
          <div className="flex flex-col">
            <label className="font-semibold">Occupation / Employment</label>
            <input
              type="text"

              name="occupation"
              placeholder="Enter The Occupation/Employment"
              value={formData.occupation}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>

          {/* Where employed / How long */}
          <div className="flex flex-col">
            <label className="font-semibold">Where employed / How long</label>
            <input
              type="text"
              name="employment"
              placeholder="Enter The Details"
              value={formData.employment}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>

          {/* Date of Birth (Auto-filled) */}
          <div className="flex flex-col">
            <label className="font-semibold">Date of Birth</label>
            <input
              type="text"
              name="dob"
              value={formData.dob}
              readOnly
              className="border rounded p-2 bg-gray-100"
            />
          </div>

          {/* Educational Qualification (Auto-filled) */}
          <div className="flex flex-col">
            <label className="font-semibold">Educational Qualification</label>
            <input
              type="text"
              name="education"
              value={formData.education}
              readOnly
              className="border rounded p-2 bg-gray-100"
            />
          </div>

          {/* Scouting Background */}
          <div className="flex flex-col">
            <label className="font-semibold">Have you been a Cub/ Scout/ etc.</label>
            <select
              name="scoutingBackground"
              value={formData.scoutingBackground}
              onChange={handleInputChange}
              className="border rounded p-2"
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          {/* Select Section (Conditional based on "Yes") */}
          {formData.scoutingBackground === "Yes" && (
            <div className="flex flex-col">
              <label className="font-semibold">Select Section</label>
              <select
                name="section"
                value={formData.section}
                onChange={handleInputChange}
                className="border rounded p-2"
              >
                <option value="">Select Section</option>
                <option value="Cub">Cub</option>
                <option value="Scout">Scout</option>
                <option value="Rover">Rover</option>
                <option value="Bulbul">Bulbul</option>
                <option value="Guide">Guide</option>
                <option value="Ranger">Ranger</option>
              </select>

              <div className="flex flex-col">
            <label className="font-semibold">Give details, Place, Years etc.</label>
            <input
              type="text"
              name="details"
              placeholder="Enter The Details"
              value={formData.details}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>
            </div>
          )}

          {/* Additional Details */}
          

          {/* Service in the Movement */}
          <div className="col-span-full font-bold pt-5 text-2xl uppercase">
            Service in the Movement as an Adult Leader
          </div>

          {/* Rank */}
          <div className="flex flex-col">
            <label className="font-semibold">Rank</label>
            <input
              type="text"
              name="rank"
              placeholder="Enter The Rank"
              value={formData.rank}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>

          {/* Years */}
          <div className="flex flex-col">
            <label className="font-semibold">Years</label>
            <input
              type="text"
              name="years"
              placeholder="Enter The Years"
              value={formData.years}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>

          {/* Warrant Nos */}
          <div className="flex flex-col">
            <label className="font-semibold">Warrant Nos</label>
            <input
              type="text"
              name="warrantNos"
              placeholder="Enter The Warrant No."
              value={formData.warrantNos}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>

          {/* Remarks */}
          <div className="flex flex-col">
            <label className="font-semibold">Remarks</label>
            <input
              type=" ```javascript
              text"
              name="remarks"
              placeholder="Enter The Remarks"
              value={formData.remarks}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>

          {/* List of Books on Scouting */}
          <div className="flex flex-col">
            <label className="font-semibold">Books You’ve Studied on Scouting</label>
            <input
              type="text"
              name="booksStudied"
              placeholder="List Of Books"
              value={formData.booksStudied}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>

          {/* Scout Subject */}
          <div className="flex flex-col">
            <label className="font-semibold">Subject of Scouting / Guiding</label>
            <input
              type="text"
              name="scoutSubject"
              placeholder="List Of Subject"
              value={formData.scoutSubject}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>

          <div className="col-span-full font-bold pt-5 uppercase text-2xl">
            Course Assistance Details
          </div>

          {/* Course Type */}
          <div className="mb-4">
            <label className="block text-black mb-2 font-semibold">Course Type</label>
            <select
              name="courseType"
              value={formData.courseType}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
              required
            >
              <option value="" disabled>Select Course Type</option>
              <option value="Basic">Basic</option>
              <option value="Advanced">Advanced</option>
            </select>
          </div>

          {/* Section */}
          <div className="mb-4">
            <label className="block text-black mb-2 font-semibold">Section</label>
            <select
              name="section"
              value={formData.section}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
              required
            >
              <option value="" disabled>Select Section</option>
              <option value="CUB">CUB</option>
              <option value="BULBUL">BULBUL</option>
              <option value="SCOUT">SCOUT</option>
              <option value="GUIDE">GUIDE</option>
              <option value="ROVER">ROVER</option>
              <option value="RANGER">RANGER</option>
            </select>
          </div>

          {/* State */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2 font-semibold">State</label>
            <select
              value={formData.selectedState}
              onChange={selectstate}
              className="outline-none bg-white rounded-md px-3 py-1 border border-gray-300 focus:border-indigo-500"
            >
              <option value="">Select State</option>
              {courseState.map((item, index) => (
                <option key={index} value={item.state}>
                  {item.state}
                </option>
              ))}
            </select>
          </div>

          {/* From Date */}
          <div className="mb-4">
            <label className="block text-black mb-2 font-semibold">From Date</label>
            <input
              type="date"
              name="fromDate"
              value={formData.fromDate}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>

          {/* To Date */}
          <div className="mb-4">
            <label className="block text-black mb-2 font-semibold">To Date</label>
            <input
              type="date"
              name="toDate"
              value={formData.toDate}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
              required
            />
          </div>

          {/* Place */}
          <div className="mb-4">
            <label className="block text-black font-semibold mb-2">Place</label>
            <input
              type="text"
              name="place"
              value={formData.place}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter Place"
              required
            />
          </div>

          {/* Leader of the Course */}
          <div className="mb-4">
            <label 
            className="block text-black font-semibold mb-2">Leader of the Course</label>
            <input
              type="text"
              name="leader"
              value={formData.leader}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter Leader's Name"
              required
            />
          </div>
        </form>
        {/* Upload Course Assisted Certificate */}
        <div className="mb-4">
          <label className="block text-black font-semibold mb-2">
            Upload Course Assisted Certificate from LOC
          </label>
          <input
            type="file"
            name="courseCertificate"
            onChange={handleFileChange}
            className="border border-gray-300 rounded-md p-2"
            required
          />
        </div>

        {/* Medical Fitness Statement */}
        <div className="mb-4 flex items-center flex-row">
          <input
            type="checkbox"
            name="fitnessStatement"
            checked={formData.fitnessStatement}
            onChange={handleChange}
            className="mr-2"
            required
          />
          <label className="text-black pt-5 ">
            I am medically fit for undergoing the strain of camping and
            training. I have not undergone any medical treatment during last six
            months.
          </label>
        </div>

        {/* Upload Fitness Certificate */}
        <div className="mb-4">
          <label className="block text-black font-semibold mb-2">
            Upload a fitness certificate by Medical Officer
          </label>
          <input
            type="file"
            name="fitnessCertificate"
            onChange={handleFileChange}
            className="border border-gray-300 rounded-md p-2"
            required
          />
        </div>

        {/* Unit Running Statement */}
        <div className="-mt-8 flex items-center">
          <input
            type="checkbox"
            name="unitRunningStatement"
            checked={formData.unitRunningStatement}
            onChange={handleChange}
            className="mr-2"
            required
          />
          <label className="text-black pt-10 ">
            I will continue to run a Unit of my section and I am prepared to
            spare time to assist in Unit Leaders Training as per Scheme of
            Training (Scout Wing) as and when invited by the State. I will
            continue to update my knowledge, skill etc by participating in
            activities of the Movement.
          </label>
        </div>

        {/* Upload Unit Running Certificate */}
        <div className="mt-4">
          <label className="block text-black font-semibold mb-2">
            Upload Unit Running Certificate from D.O.C.(S) or D.T.C.(S)
            countersigned by DC(A.R.)
          </label>
          <input
            type="file"
            name="unitCertificate"
            onChange={handleFileChange}
            className="border border-gray-300 rounded-md p-2"
            required
          />
        </div>

        <div
          onClick={handleSubmit}
          className="bg-[#1D56A5] mt-5 text-white font-bold py-2 px-4 rounded shadow-md hover:bg-blue-600 transition duration-300 w-1/2 mx-auto text-center cursor-pointer"
        >
          Submit
        </div>
      </div>
    </>
  );
};

export default FormComponent;