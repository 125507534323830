import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Carousel from "./Carousel";
import image from "../img/big logo (1).png";
import { BASE_URL } from "../constant/constant";
import axios from "axios";
const Home = () => {
  const [user, setUser] = useState(null);

  const [error, setError] = useState("");

  const [certificatePath, setCertificatePath] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUser = async () => {
    setLoading(true);
    setError(""); // Clear previous error
    try {
      const bsgUid = localStorage.getItem("bsgUid");
      console.log(bsgUid, "bsgUid");
      const response = await fetch(`${BASE_URL}/api/v1/user/${bsgUid}`);
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const data = await response.json();
      console.log(data, "data");
      setUser(data);
    } catch (err) {
      setError(err.message);
      // toast.error(`Error fetching user data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleGenerateCertificate = async () => {
    setLoading(true);
    try {
      // const bsgUid = localStorage.getItem("bsgUid");
      const bsgUid="123432";
      const response = await axios.get(
        `${BASE_URL}/api/v1/certificate/${bsgUid}`
      );

      // Update the certificatePath with the full URL from the backend response
      const certificateURL = `http://localhost:3005${response.data.certificatePath}`;
      setCertificatePath(certificateURL);
    } catch (error) {
      console.error("Error generating certificate:", error);
      alert("An error occurred while generating the certificate.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="font-bold text-3xl -mt-9  text-[#1D56A5] uppercase">
        National Portal for BSG Trainers 
      </h1>
      <div className="text-md mt-5 ">
      The Bharat Scouts and Guides has launched an updated portal for Assistant Leader Trainers and Leader Trainers from both wings, allowing members to view trainer details at a glance. Trainers play a crucial role in shaping the future of youth in India.<br/>


These trainers are responsible for imparting essential leadership skills, values, and practical knowledge to both youth and new adults. They provide guidance and mentorship, ensuring that members are well-equipped to face challenges in their personal and social lives while also strengthening the movement.<br/>


BSG will continue to honor the dedicated voluntary services of trainers who conduct and assist with events, workshops, and leadership training camps as per the guidelines of the Bharat Scouts and Guides, following the Scheme of Trainings.<br/>


Their role is vital in instilling qualities such as discipline, teamwork, and social responsibility, empowering youth to become effective leaders and change-makers in their communities and beyond.<br/>
      </div>

      <ToastContainer />

      {/* <div className="p-4">
        <button
          onClick={handleGenerateCertificate}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate Certificate"}
        </button>

        {certificatePath && (
          <div className="mt-4">
            <h2>Certificate:</h2>
            <a
              href={certificatePath}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              View Certificate
            </a>
            
          </div>
        )}
      </div> */}
    </>
  );
};

export default Home;
